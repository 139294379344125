import { useEffect, isValidElement } from 'react';

function isRenderable(obj) {
  return typeof obj === "number" || typeof obj === "string" || typeof obj === "boolean" || obj === null || isValidElement(obj);
}

const useMultipageWizard = (pageHookFunctions = [], initialData = {}, opts = {}) => {
  if (!Array.isArray(pageHookFunctions) || pageHookFunctions.find(x => typeof x !== "function")) {
    throw new Error("The first argument of useMultipageWizard must be an array of page hooks");
  }

  if (typeof initialData !== "object") {
    throw new Error("The second argument of useMultipageWizard must be an object");
  }
  
  let data = {...initialData};
  let elementToBeShown = null;
  let currentIndex = 0;

  for (var i = 0; i < pageHookFunctions.length; i++) {
    const result = pageHookFunctions[i](data, elementToBeShown === null);
    if (!Array.isArray(result)) {
      throw new Error("The return value of a page hook must be an array")
    }
    const [element, newData] = result;
    if (!isRenderable(element)) {
      throw new Error("The first element of a page hook's return value must be null, a string, a number, a boolean, or a React Element")
    }
    if (newData) {
      if (typeof newData !== "object") {
        throw new Error("The second element of a page hook's return value must be an object")
      }
      data = {...data, ...newData};
    }

    if (element && elementToBeShown === null) {
      elementToBeShown = element;
      currentIndex = i;
    }
  }

  useEffect(() => {
    let scrollTarget = 0;
    for (var i = opts.ref && opts.ref.current; !!i; i = i.offsetParent) scrollTarget += i.offsetTop;
      
    document.body.scrollTop = Math.min(document.body.scrollTop, scrollTarget); // For Safari
    document.documentElement.scrollTop = Math.min(document.documentElement.scrollTop, scrollTarget); // For Chrome, Firefox, IE and Opera
  }, [currentIndex, opts.ref]);

  return [elementToBeShown, data];
} 

export default useMultipageWizard;