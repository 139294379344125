import React, { useState, useEffect, useCallback } from 'react';
import Loading from './Loading';
import { toTimestamp, toAge } from './util/time';
import useApiFetch from './useApiFetch';
import apiRoutes from './apiRoutes';
import { Redirect, Link } from 'react-router-dom';
import InterpretTrackStatus from './util/InterpretTrackStatus';
import DataGrid from './DataGrid';
import RangeInput from './RangeInput';
import Switch from './Switch';

const videoStatusToString = (videoStatus) =>  {
  switch (videoStatus) {
    case 0: return "Video queued for processing";
    case 1: return "Video processing";
    case 2: return "Video ready";
    default: return "Video status unknown";
  }
}

const interpretInviteStatus = (inviteStatus) => {
  switch (inviteStatus) {
    case 0:
      return "pending";
    case 1:
      return "sent";
    case 2:
      return "accepted";
    case 3:
      return "completed";
    default:
      return "unknown";
  }
}

const usePeriodicFetch = (url) => {
  const fetch = useApiFetch();
  const [state, setState] = useState();

  useEffect(() => {
    const interval = setInterval(() =>{
      fetch(url)
        .then(res => res.json())
        .then(json => setState(json));
    }, 2000);

    return () => clearInterval(interval);
  }, [url, fetch]);

  return [state, setState];
}

const SessionStatus = ({sessionId}) => {
  const [deleted, setDeleted] = useState(false);
  const fetch = useApiFetch();

  const [session, setSession] = usePeriodicFetch(apiRoutes.sessionDetails(sessionId));
  const [trackInfos, setTrackInfos] = usePeriodicFetch(apiRoutes.tracksForSession(sessionId));
  const [invites, setInvites] = usePeriodicFetch(apiRoutes.sessionInvites(sessionId));
  const [notes, setNotes] = usePeriodicFetch(apiRoutes.sessionNote(sessionId));

  const deleteSession = useCallback(e => {
    setSession(null);
    e.preventDefault();
    fetch(apiRoutes.sessionDetails(sessionId), {method: "DELETE"})
      .catch()
      .then(() => {
        setDeleted(true);
      });
  }, [sessionId, fetch, setSession]);

  const deleteTrack = useCallback(trackId => e => {
    setTrackInfos(null);
    e.preventDefault();
    fetch(apiRoutes.trackDetails(sessionId, trackId), {method: "DELETE"});
  }, [sessionId, fetch, setTrackInfos])

  const setTrackVolume = useCallback(trackId => {
    var throttle = null;
    return e => {
      if (throttle) {
        clearTimeout(throttle);
      }
      const value = e.target.value;
      throttle = setTimeout(() => {
        fetch(apiRoutes.trackDetails(sessionId, trackId), {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            mixVolume: value
          })
        })
      }, 1000);
    }
  }, [sessionId, fetch])

  const setTrackMozaicInclusion = useCallback(trackId => e => {
    fetch(apiRoutes.trackDetails(sessionId, trackId), {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        includeInMozaic: !!e
      })
    })
  }, [sessionId, fetch]);

  const closeSession = useCallback(toggle => e => {
    e.preventDefault();
    setSession(null);
    fetch(apiRoutes.closeSession(sessionId), {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        closed: !!toggle
      })
    })
  }, [sessionId, fetch, setSession]);

  const remix = useCallback(e => {
    e.preventDefault();
    fetch(apiRoutes.remix(sessionId), {method: "POST"});
  }, [sessionId, fetch])

  const triggerMozaic = useCallback(e => {
    e.preventDefault();
    fetch(apiRoutes.triggerMozaic(sessionId), {method: "POST"})
  }, [sessionId, fetch])

  const revokeInvite = useCallback(id => e => {
    e.preventDefault();
    fetch(apiRoutes.sessionInvites(sessionId), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ids: [id]
      })
    }).then(() => setInvites(is => is.filter(i => i.id !== id)));
  }, [sessionId, fetch, setInvites]);

  const deleteNote = useCallback(id => e => {
    e.preventDefault();
    fetch(apiRoutes.sessionNote(sessionId, id), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: id
      })
    }).then(() => setNotes(n => n.filter(i => i.id !== id)))
  }, [sessionId, fetch, setNotes])

  if (deleted) {
    return <Redirect push to="/admin" />;
  }

  if (!trackInfos || !session) {
    return (
      <>
        <h2>Session Status</h2>
        <Loading big>Loading...</Loading>
      </>
    );
  }

  const ongoingTracks = trackInfos.filter(x => InterpretTrackStatus.asActive(x.trackStatus));
  const ongoingTracksString = ongoingTracks.length ? `${ongoingTracks.length} tracks are currently being recorded` : "";
  const cancelledTracks = trackInfos.filter(x => InterpretTrackStatus.asDeleted(x.trackStatus));
  const cancelledTracksString = cancelledTracks.length ? `${cancelledTracks.length} previously recorded tracks were cancelled` : "";
  const otherTracks = [ongoingTracksString, cancelledTracksString].filter(x => !!x).join(", and ");

  return (
    <>
      <h2>Session status: {session.title}</h2>
      <h3>Tracks ({trackInfos.filter(x => InterpretTrackStatus.asComplete(x.trackStatus)).length})</h3>
      <DataGrid compact>
        {trackInfos.filter(x => InterpretTrackStatus.asComplete(x.trackStatus)).map(x => (
          <DataGrid.Row key={x.id}>
            <DataGrid.Cell hiddenOnMobile>
              {x.createdUtc ? (
                <abbr title={toTimestamp(x.createdUtc)}>{toAge(new Date(x.createdUtc).valueOf())} ago</abbr>
              ) : "Recently"}
            </DataGrid.Cell>
            <DataGrid.Cell>
              {x.part} part by <b>{x.name}</b>. 
              {session.videoMode !== 0 && <div>{videoStatusToString(x.videoStatus)}.</div>}
            </DataGrid.Cell>
            <DataGrid.Cell actions>
              <RangeInput min={0} max={1} step={0.01} defaultValue={x.mixVolume} onChange={setTrackVolume(x.id)} />
              {session.videoMode !== 0 && <Switch defaultValue={x.includeInMozaic} onToggle={setTrackMozaicInclusion(x.id)}>Include video</Switch>}
              <button className="button--link" onClick={deleteTrack(x.id)}>Delete</button>
            </DataGrid.Cell>
          </DataGrid.Row>
        ))}
      </DataGrid>

      {otherTracks && <p>Additionally, {otherTracks} </p>}
      <h3>Invitations</h3>
      {!!invites && (
        <DataGrid compact>
          {invites.map(invite => (
              <DataGrid.Row key={invite.id}>
                <DataGrid.Cell>{invite.email}</DataGrid.Cell>
                <DataGrid.Cell>{toAge(invite.timestamp)} ago</DataGrid.Cell>
                <DataGrid.Cell>{interpretInviteStatus(invite.status)}</DataGrid.Cell>
                <DataGrid.Cell>{!!invite.deadline ? toTimestamp(invite.deadline) : "-"}</DataGrid.Cell>
                <DataGrid.Cell actions><button onClick={revokeInvite(invite.id)} className="button--link">Revoke</button></DataGrid.Cell>
              </DataGrid.Row>
            ))}
        </DataGrid>
      )}
      <Link className="button button--inline" to={`/admin/session/${session.sessionId}/invite`}>Invite more</Link>

      <h3>Notes</h3>
      {!!notes && (
        <DataGrid compact>
          {notes.map(note => (
            <DataGrid.Row key={note.id}>
              <DataGrid.Cell>{toAge(note.timestamp)} ago</DataGrid.Cell>
              <DataGrid.Cell>{note.body.split("\n").map(line => <p key="line">{line}</p>)}</DataGrid.Cell>
              <DataGrid.Cell actions><button onClick={deleteNote(note.id)} className="button--link">Delete</button></DataGrid.Cell>
            </DataGrid.Row>
          ))}
        </DataGrid>
      )}
      
      <Link className="button button--inline" to={`/admin/session/${session.sessionId}/addNote`}>Add a note</Link>

      <h3>
        Actions
      </h3>
      <p>
        <button className="button--link" onClick={deleteSession}>Delete</button>
      </p>
      <p>
        <Link to={`/sessions/${session.sessionId}/download`}>Download</Link>
      </p>
      <p>  
        <a href={`${apiRoutes.sessionDetails(session.sessionId)}/downloadVideo`}>Download video</a>
      </p>
      <p>  
        <Link to={`/sessions/${session.sessionId}/stream`}>Listen</Link>
      </p>
      <p>
        <button className="button--link" onClick={remix}>Remix</button>
      </p>
      <p>
        <button className="button--link" onClick={triggerMozaic}>Trigger mozaic generation</button>
      </p>
      {session.isClosed ? (
        <p>
          <button className="button--link" onClick={closeSession(0)}>Open session</button>
        </p>
      ) : (
        <p>
          <button className="button--link" onClick={closeSession(1)}>Close session</button>
        </p>
      )}
    </>
  )
}

export default SessionStatus;