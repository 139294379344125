import React from 'react';
import { ReactComponent as Logo } from './loading.svg';
import './Loading.css'

const Loading = ({children, big}) => (
  <span className={`loading ${big ? "loading--big" : ""}`}>
    <Logo />
    <em className="loading__message">{children}</em>
  </span>
);

export default Loading;