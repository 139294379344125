import React, { useState, useMemo } from 'react';
import extractData from '../util/extractData';
import Form from '../Form';

const useSetNamePage = ({claimsName}) => {
  const [name, setName] = useState("");

  if (claimsName && !name) {
    setName(claimsName);
  }

  const element = useMemo(() => {
    if (!name) {
      return (
        <Form onSubmit={extractData(d => setName(d.name))}>
          <label htmlFor="setName">
            What's your full name?
          </label>
          <input id="setName" type="text" name="name" />
          <button>Next</button>
        </Form>
      )
    }
    return null;
  }, [name]);

  return [element, {name}];
}

export default useSetNamePage;