import React from 'react';
import './Page.css';

const Page = ({children}) => (
  <div className="page">
    {children}
  </div>
);

export default Page;
