import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import './useVideoPlayer.css';

import Hls from 'hls.js';
import { AuthContext, useAuthenticationState } from "./Authentication";
import { useUploadContribution } from "./UploadContribution";

const makeVideoFrame = ({setPlayer, setPlaying, passive, src}) => {
  const Element = ({children}) => {
    const elementRef = useRef();
    const { tokenUser } = useContext(AuthContext);
    const userState = useAuthenticationState()
    const [token, setToken] = useState("");
    const { cork } = useUploadContribution();

    useEffect(() => {
      if (tokenUser) {
        tokenUser.getIdToken().then(t => setToken(t));
      }
    }, [tokenUser]);

    const [innerPlaying, setInnerPlaying] = useState(false);
    
    useEffect(() => {
      if (!Hls.isSupported() 
        || !elementRef.current
        || (userState === useAuthenticationState.INITIALISING) 
        || (userState === useAuthenticationState.SIGNED_IN && !token)
      ) {
        return; 
      }
      
      const parent = elementRef.current.parentElement;
      elementRef.current.width = Math.min(640, parent.clientWidth) ;
      elementRef.current.height = 9/16* Math.min(640, parent.clientWidth) ;
      
      var hls = new Hls({
        startPosition:0,
        manifestLoadingMaxRetry: 4,
        xhrSetup: xhr => xhr.setRequestHeader("Authorization", `Basic ${token}`)
      });
      hls.loadSource(src);
      hls.attachMedia(elementRef.current);
      
      let uncorker;
      hls.on(Hls.Events.BUFFER_APPENDED, (_event, data) => {
      const length = data.timeRanges.video.length;
        if (length) {
          const timeLeft = data.timeRanges.video.end(length - 1) - elementRef.current.currentTime;
          // console.log('timeleft (video)', timeLeft);
          if (timeLeft < 10 && !uncorker) {
            uncorker = cork();
          } else if (timeLeft >= 10 && uncorker) {
            uncorker();
            uncorker = null;
          }
        }
      })

      hls.on(Hls.Events.MANIFEST_PARSED, function() {
        setPlayer({
          setVolume: v => {if(elementRef.current) elementRef.current.volume = v},
          getDuration: () => elementRef.current ? elementRef.current.duration : 0,
          getPlaybackRate: () => elementRef.current ? elementRef.current.playbackRate : 0,
          getPlayerState: () => elementRef.current && elementRef.current.paused ? 0 : 1,
          getCurrentTime: () => elementRef.current ? elementRef.current.currentTime : null,
          hasFinished: () => elementRef.current ? elementRef.current.ended : false,

          playVideo: () => { 
            if (elementRef.current) {
              elementRef.current.play();
              setPlaying(true);
              setInnerPlaying(true);
            }
          },
          stopVideo: () => { 
            if (elementRef.current) {
              elementRef.current.pause();
              elementRef.current.currentTime = 0;
              setPlaying(false);
              setInnerPlaying(false);
            }
          },
          seekTo: (position) => { if (elementRef.current) { elementRef.current.currentTime = position }}
        });
      });
    
      return () => {
        // hls.detachMedia();
        hls.destroy();
        setPlayer(null);
        setPlaying(false);
        if (uncorker) {
          uncorker();
        }
      }
    }, [src, token, userState, cork]) // eslint-disable-line

    return (
      <div className={`useVideoPlayer__playwrap ${passive && "useVideoPlayer__playwrap--passive"} ${!innerPlaying && "useVideoPlayer__playwrap--inactive"}`}>
        <video ref={elementRef} controls={!passive} className="useVideoPlayer__video" />
        {children}
      </div>
    );
  }

  Element.displayName = "VideoFrame";

  return Element;
}

const useVideoPlayer = (src, passive = false) => {
  const [player, setPlayer] = useState();
  const [playing, setPlaying] = useState(false);

  const VideoFrame = useMemo(
    () => makeVideoFrame({src, passive, setPlayer, setPlaying}), 
    [src, passive]);

  return [VideoFrame, player, playing];
}

export default useVideoPlayer;