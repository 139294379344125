import React, { useRef, useMemo, useCallback } from 'react';
import './useProgressBar.css';

const useProgressBar = (label) => {
  const ref = useRef();

  const element = useMemo(() => (
    <>
      <span className="useProgressBar__label">{label}</span>
      <svg width="200" height="10" xmlns="http://www.w3.org/2000/svg">
        <rect x="0%" y="0%" style={{fill: "#5945ba", height: "100%", width: "0%"}} ref={ref}/>
        <rect x="99%" y="0%" style={{fill: "#5945ba", width: "1px", height: "100%"}} />
      </svg>
    </>
  ), [ref, label]);

  const setFraction = useCallback(fraction => {
    if (ref.current) {
      const normFraction = Math.max(0, Math.min(1, fraction));
      const isEmpty = normFraction < 0.01;
      ref.current.style.width = Math.floor(normFraction * 100) + "%";
      ref.current.style.transition = isEmpty ? "width 200ms ease" : "width 50ms linear";
    }
  }, [ref]);

  return [setFraction, element];
}

export default useProgressBar;