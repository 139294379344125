import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { ClapContext } from './DetectClap';
import SampleSet from '../util/SampleSet';
import useProgressBar from '../useProgressBar';

const CYCLE_TIME_MS = 1000;
const REQUIRED_SAMPLE_COUNT = 16;

const CalibrateByVisual = ({onFinish, active}) => {
  const { onClap } = useContext(ClapContext);
  const ref = useRef();
  const outerRef = useRef();
  const sampleSet = useMemo(() => new SampleSet(), []);
  const [ts, setTs] = useState(null);
  const [visualTs, setVisualTs] = useState(null);
  const [setProgress, progress] = useProgressBar("Progress:");

  useEffect(() => {
    onClap(() => setTs(new Date().valueOf()));
  }, [onClap]);


  useEffect(() => {
    if (!active) {
      return;
    }
    let zeroDateValue = new Date().valueOf();
    let prevDateValue = zeroDateValue;
    let lastT;
    let id = null;

    function update() {
      if (!ref.current) {
        return;
      }
      id = requestAnimationFrame(update);
      
      const valueOf = new Date().valueOf();
      const t = (valueOf - zeroDateValue) % CYCLE_TIME_MS;
      const tMirrored = t > CYCLE_TIME_MS / 2 ? CYCLE_TIME_MS - t : t;
      const y = 20 + 65 * Math.pow(2*tMirrored/CYCLE_TIME_MS, 2);

      ref.current.setAttribute("cy", y);

      if (t > CYCLE_TIME_MS/2 && lastT <= CYCLE_TIME_MS/2) {
        setVisualTs(prevDateValue);
      } 
      prevDateValue = valueOf;
      lastT = t;
    };

    update();

    return () => cancelAnimationFrame(id)
  }, [active]);

  useEffect(() => {
    if (active) {
      setProgress(0);
      setVisualTs(null);
      setTs(null);
      sampleSet.clear();
    }
    if (ref.current) {
      ref.current.setAttribute("cy", 20);
    }
  }, [active]) //eslint-disable-line

  useEffect(() => {    
    if (!outerRef.current) {
      return;
    }
    outerRef.current.style.opacity = 1;
    setTimeout(() => {
      if (outerRef.current) {
        outerRef.current.style.opacity = 0
      }
    }, 50);
  }, [visualTs]);

  useEffect(() => {
    if (visualTs === null || ts === null || !active) {
      return;
    }
    if (Math.abs(visualTs - ts) <= CYCLE_TIME_MS / 3) {
      sampleSet.push(ts - visualTs);
      setProgress(sampleSet.getCount() / REQUIRED_SAMPLE_COUNT);
      if (sampleSet.getCount() >= REQUIRED_SAMPLE_COUNT) {
        onFinish(sampleSet.getMedian());
      }
    }
  }, [visualTs, ts, sampleSet, setProgress, onFinish, active])

  return <>
    <svg id="theMap" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <circle cx="50" cy="20" r="10" fill="#5945ba" ref={ref} />
      <line x1="20" x2="80" y1="95" y2="95" strokeWidth={5} stroke="#5945ba" />
    </svg>
    <p>
      {progress}
    </p>

  </>;  
}

export default CalibrateByVisual;