import React, { useCallback } from 'react';
import ReactModal from 'react-modal';
import useVideoPlayer from './useVideoPlayer';
import apiRoutes from './apiRoutes';
import SynchronisedPlayback from './SynchronisedPlayback';

const PracticeModal = ({isOpen, onClose, title, sessionId}) => {
  const [VideoFrame, player] = useVideoPlayer(apiRoutes.quevideoStream(sessionId), false);
  const getCurrentTimestamp = useCallback(() => player && player.getPlayerState() ? player.getCurrentTime() : null, [player]);

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel={`Practice ${title}`}
      style={{
        content : {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: "90%",
          maxWidth: "600px",
          borderRadius: "15px",
          boxShadow: "3px 3px 5px 0px #5945ba",
          MozBoxShadow: "3px 3px 5px 0px #5945ba",
          WebkitBoxShadow: "3px 3px 5px 0px #5945ba"
        }
      }}
    >
      <h3>Practice {title}</h3>
      <VideoFrame />
      <SynchronisedPlayback getCurrentTimestamp={getCurrentTimestamp}>
        <SynchronisedPlayback.Audio src={apiRoutes.backingTrackStream(sessionId)} />
      </SynchronisedPlayback>
      <button className="userProfile__close button--link" onClick={onClose}>Close</button>
      <div className="clearfix" />
    </ReactModal>
  );
}

export default PracticeModal;