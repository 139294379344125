import React, { useMemo, useState, useEffect } from 'react';
import { useAuthenticationState, useAuthentication, AuthContext } from '../Authentication';
import Loading from '../Loading';
import Error from '../Error';
import useApiFetch from '../useApiFetch';
import apiRoutes from '../apiRoutes';
import { useContext } from 'react';

const useAuthenticationPage = ({ sessionId, videoData = {} }, isNextUp) => {
  const [isWhitelisted, setIsWhitelisted] = useState();

  const { claims } = useAuthentication({
    allowAnonymous: !isNextUp || videoData.allowAnonymous
  });
  const { tokenUser } = useContext(AuthContext);
  const fetch = useApiFetch()

  useEffect(() => {

    if (!isNextUp || videoData.allowSharing === undefined) {
      // not loaded
      return;
    }
    if (videoData.allowSharing) {
      return setIsWhitelisted(true)
    }

    fetch(apiRoutes.isInvited(sessionId))
      .then(x => x.json())
      .then(invited => setIsWhitelisted(invited));

  }, [fetch, sessionId, videoData, isNextUp])

  const authState = useAuthenticationState();

  const isAuthenticated = (
    authState === useAuthenticationState.SIGNED_IN ||
    (authState === useAuthenticationState.ANONYMOUS && videoData.allowAnonymous)
  ) && (
      isWhitelisted
    );

  const element = useMemo(() =>
    isWhitelisted === false ? (
      <Error>
        <Error.Title>
          You cannot join this session
        </Error.Title>
        <Error.Message>
          The owner of this session has marked the session as private and
          has not invited you to join, or cancelled your invitation.
        </Error.Message>
        <Error.Message>
          If you believe this is was an error, please contact the session owner.
        </Error.Message>
      </Error>
    ) : !isAuthenticated ? (
      <Loading big>Loading...</Loading>
    ) : null, [isAuthenticated, isWhitelisted]);
  const claimsName = 
    (tokenUser && tokenUser.displayName) ||
    (claims && claims.name) ||
    null;

  return [element, { claimsName }];
}

export default useAuthenticationPage;