import React from "react";
import './Home.css';
import hero from "./hero.jpg";
import { ReactComponent as Find } from './find.svg';
import { ReactComponent as Hear } from './hear.svg';
import record from './record.png';

import { Link } from "react-router-dom";
import Page from "./Page";
import InlineCta from "./InlineCta";


const Home = () => (
  <>
    <div className="home__hero">
      <img src={hero} alt="" />
      <div className="home__herotext">
        <h1>Make music anywhere.</h1>
        <p>
          Duruflé is an app that lets musical ensembles
          create performances remotely and rehearse from afar. 
        </p>
        <Link className="home__cta" to="/sessions">
          Join a session
        </Link>
      </div>
    </div>
    <Page>
      <h2>Here&apos;s how it works:</h2>
      <ul className="home__steps">
        <li>
          <h3>1.&ensp;Find a session to join</h3>
          <Find />
          <span>Browse the public sessions available; some sessions are even live broadasts! Alternatively, your conductor might send you a link to a closed session.</span>
        </li>
        <li>
          <h3>2.&ensp;Record your performance</h3>
          <img src={record} alt="" />
          <span>You'll see a video with a conductor and a backing track. Play or sing along to add your sound to the mix.</span>
        </li>
        <li>
          <h3>3.&ensp;Listen to to the mix</h3>
          <Hear />
          <span>You can hear yourself as part of the mix as soon as you are done recording.</span>
        </li>
      </ul>

      <InlineCta>
        <InlineCta.Question>
          Ready to give it a go?
        </InlineCta.Question>
        <InlineCta.Answer>
          <Link className="button" to="/sessions">Join a session</Link>
        </InlineCta.Answer>
      </InlineCta>
    </Page>
  </>
)

export default Home;