import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import InlineCta from '../InlineCta';

const useFinishedPage = () => {
  const element = useMemo(() => (
    <>
      <p>Your performance has been added to the ensemble mix &ndash; nice one!</p>
      <InlineCta>
        <InlineCta.Question>
          Want to hear what it sounds like?
        </InlineCta.Question>
        <InlineCta.Answer>
          <Link className="button button--inline" to={`/profile`}>Go to My recordings</Link>
        </InlineCta.Answer>
      </InlineCta>
    </>
  ), [])

  return [element];
}

export default useFinishedPage;