import React, { useEffect, useState, useCallback } from "react";
import apiRoutes from "./apiRoutes";
import useVideoPlayer from "./useVideoPlayer";
import SynchronisedPlayback from "./SynchronisedPlayback";
import { Link, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import ChatLink from "./ChatLink";

import './StreamSession.css';
import useApiFetch from "./useApiFetch";
import useNotification from "./useNotification";
import InterpretTrackStatus from "./util/InterpretTrackStatus";
import CopyLink from "./CopyLink";
import Banner from "./Banner";

const StreamSession = ({sessionId, rtr, share}) => {
  const [session, setSession] = useState();
  const [tracks, setTracks] = useState([]);
  const [shareableLink, setShareableLink] = useState();
    
  const [participants, setParticipants] = useState([]);
  const [cancelling, setCancelling] = useState();
  const [audioOffset, setAudioOffset] = useState(0);
  const fetch = useApiFetch();

  const [showNotification, notificationElement] = useNotification();

  useEffect(() => {
    const calibration = Cookies.getJSON('calibration');
    const isValid = calibration && calibration.audioOffset !== undefined && calibration.visualOffset !== undefined;
    if (isValid) {      
      setAudioOffset((calibration.audioOffset - calibration.visualOffset)/1000);
    }
  }, [])

  useEffect(() => {
    fetch(apiRoutes.sessionDetails(sessionId))
      .then(r => r.json())
      .then(json => setSession(json))
  }, [sessionId, fetch])

  useEffect(() => {
    fetch(apiRoutes.shareableLink(sessionId))
      .then(r => r.text())
      .then(link => {
        if (link) {
          const last = window.location.href.indexOf("?")
          const queryless = window.location.href.slice(0, last === -1 ? undefined : last);
          setShareableLink(`${queryless}?share=${encodeURIComponent(link)}`)
        }
      })
  }, [sessionId, fetch]);

  useEffect(() => {
    fetch(apiRoutes.trackBreakdown(sessionId))
      .then(r => r.json())
      .then(json => setParticipants(json))
  }, [sessionId, fetch])

  useEffect(() => {
    if (rtr) {
    fetch(apiRoutes.trackDetails(sessionId, rtr))
      .then(r => r.json())
      .then(json => setTracks([json].filter(x => !InterpretTrackStatus.asDeleted(x))))
    }
  }, [sessionId, rtr, fetch])

  const [VideoFrame, player] = useVideoPlayer(apiRoutes.mozaicStream(sessionId, share));
  const getCurrentTime = useCallback(() => player && player.getPlayerState() === 1 ? player.getCurrentTime() : null, [player])

  const [playerDefaultsSet, setPlayerDefaultsSet] = useState();

  useEffect(() => {
    if (player && !playerDefaultsSet) {
      player.setVolume(0);
      setPlayerDefaultsSet(true);
    }
  }, [player, playerDefaultsSet]);

  const requestCancel = useCallback(() => {
    const total = participants.reduce((a,b) => a + b.total, 0);
    if (total <= 10) {
      showNotification(
        "Are you sure you want to leave the session?",
        close => (
          <>
            <p>
              There are not a lot of other participants in the mix right now.
            </p>
            <p>
              Your recording forms part of a <b>backing track</b> that makes it easier for
              other participants to record their part. Even if you made mistakes or didn&apos;t
              get that perfect sound, your contribution helps other 
              participants.
            </p>
            <p>
              Would you be okay to keep your part in the mix for now?
            </p>
            <button onClick={close}>
              Okay &ndash; keep me in the mix
            </button>
            <p>
              <button onClick={() => setCancelling(true)} className="button--link streamSession__delete">
                No &ndash; please remove me from the mix
              </button>
            </p>
          </>
        )
      );
    } else {
      setCancelling(true);
    }
  }, [participants, showNotification]);

  if (!session) {
    return <h2>Loading</h2>;
  }

  if (cancelling) {
    return <Redirect push to={`/sessions/${sessionId}/track/${tracks[0].id}/undo`} />;
  }

  const showVideoDelayNote = session && tracks && tracks[0] && session.videoMode !== 0 && new Date().valueOf() - tracks[0].created < 24 * 3600 * 1000;

  return (
    <div className="streamSession">
      <h2>{(session && session.title) || "Loading"} &ndash; recording</h2>
      {!!showVideoDelayNote && (
        <Banner>
          <p>
            It can take <b>up to 24 hours</b> for your video to appear 
            in the mix &ndash; but your sound is already in there!
          </p>
        </Banner>
      )}
      {!!participants.length && (
        <>
          <ul className="streamSession__stats" title="This recording includes:">
            {participants.map(p => (
              <li key={p.part}>
                <b>{p.total}</b> {p.part} {p.total === 1 ? "part" : "parts"}
                {tracks && !!tracks.length && tracks[0].part === p.part && <> (<em>including you!</em>)</>}
              </li>
            ))}
          </ul>
        </>
      )}
      <VideoFrame />
      <h3>Volume control</h3>
      <SynchronisedPlayback getCurrentTimestamp={getCurrentTime} offset={audioOffset}>
        <SynchronisedPlayback.Audio src={apiRoutes.backingTrackStream(sessionId)} initialVolume={0} label="Backing track" controls />
        <SynchronisedPlayback.Audio src={apiRoutes.stream(sessionId)} initialVolume={1} label="Participants" controls />
      </SynchronisedPlayback>
      {notificationElement}
      {shareableLink && (
        <>
          <h3>Share this video</h3>
          <p>Want to show this to your friends? Share the following link:</p>
          <p>
            <CopyLink value={shareableLink} />
          </p>
        </>
      )}
      {tracks && !!tracks.length && (
        <>
          <h3>You are in this mix!</h3>
          <p>You have contributed {tracks[0].part[0].match(/^[aeio]/i) ? "an" : "a"} <b>{tracks[0].part}</b> part &ndash; can you hear yourself in the mix?</p>
          {!session.isClosed && (
            <p>
              If you are not happy with your sound, you can <Link to={`/record/${sessionId}`}>re-record your part</Link> at any time.
            </p>
          )}
          <h3>Leave the session</h3>
          <p>
            You can also request to delete your part permanently by <button className="button--link" onClick={requestCancel}>leaving the session</button>.
          </p>
        </>  
      )}
      
      <h3>How it works</h3>
      <ul>
        <li>Play the above video - you'll hear the session recording playing alongside</li>
        <li>You can skip to any place in the video - the session recording will stay in sync</li>
        <li>Premium users can <Link to={`/sessions/${sessionId}/download`}>download an MP3</Link> of the session recording</li>
        <li>
          If it doesn&apos;t sound right, please <ChatLink>write me some feedback</ChatLink>.
        </li>
      </ul>
    </div>
  );
}

export default StreamSession;