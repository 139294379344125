import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import useApiFetch from '../useApiFetch';
import apiRoutes from '../apiRoutes';

const useConfirmDraftReplacementPage = ({sessionId, finishedTrack, videoData = {}, shouldOverride}) => {
  const [complete, setComplete] = useState();
  const [submitting, setSubmitting] = useState();
  const [cancelled, setCancelled] = useState();

  const fetch = useApiFetch();


  useEffect(() => {
    setComplete(!shouldOverride);
  }, [shouldOverride]);

  const existingTrack = videoData.existingTrack;

  const doOverride = useCallback(() => {
    setSubmitting(true);

    if (shouldOverride) {
      fetch(apiRoutes.swapTracks(sessionId), {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({oldTrack: existingTrack, newTrack: finishedTrack})
      }).then(() => setComplete(true));
    }
  }, [shouldOverride, sessionId, finishedTrack, existingTrack, fetch]);

  const doCancel = useCallback(() => {
    setSubmitting(true);
    fetch(apiRoutes.trackDetails(sessionId, finishedTrack), {method: "DELETE"})
      .then(() => setCancelled(true));
  }, [sessionId, finishedTrack, fetch]);

  const element = useMemo(() => complete ? null : (
    <>
      <h3>
        Are you happy with this take?
      </h3>
      <p>
        You can replace your previous take with this one, or keep the old one.
      </p>
      <button disabled={!!submitting} onClick={doOverride}>Yes &ndash; replace my previous take</button>
      <p>
        <button disabled={!!submitting} className="button--link" onClick={doCancel}>No &ndash; keep my previous take</button>
      </p>
      {cancelled && (
        <>
          <p>Okay &ndash; we&apos;ll keep your previous take.</p>
          <p>
            <Link to="/profile">Return to My recordings</Link>
          </p>
        </>
      )}
    </>
  ), [complete, cancelled, submitting, doOverride, doCancel])

  return [ element ];
}

export default useConfirmDraftReplacementPage;
