import React, { useState, useEffect, useCallback } from 'react';
import routes from './apiRoutes';
import uiRoutes from './uiRoutes';
import "./Sessions.css";
import { Link } from 'react-router-dom';
import { useAuthentication } from './Authentication';
import useApiFetch from './useApiFetch';
import Loading from './Loading';
import apiRoutes from './apiRoutes';
import DataGrid from './DataGrid';

const SessionsGrid = () => {
  const fetch = useApiFetch();
  const [sessions, setSessions] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    fetch(routes.adminSession)
      .then(r => r.json())
      .then(s => {
        setSessions(s);
        setLoading(false);
        setError(false)
      })
      .catch(e => setError(true))

    const interval = window.setInterval(() => {
      fetch(routes.adminSession)
        .then(r => r.json())
        .then(s => {
          setSessions(s);
          setLoading(false);
          setError(false);
        })
        .catch()
    }, 5000)

    return () => window.clearInterval(interval);
  }, [fetch]);

  if (error) {
    return <p>This failed to load. Try refreshing the page.</p>
  }
  
  if (loading) {
    return <Loading big>Loading...</Loading>
  }

  if (!sessions || !sessions.length) {
    return <p>No sessions yet!</p>
  }

  return (
    <DataGrid compact>
      <DataGrid.Row>
        <DataGrid.Cell>Name</DataGrid.Cell>
        <DataGrid.Cell>Duration</DataGrid.Cell>
        <DataGrid.Cell>Active performances</DataGrid.Cell>
        <DataGrid.Cell>Complete performances</DataGrid.Cell>
        <DataGrid.Cell></DataGrid.Cell>
      </DataGrid.Row>
      {sessions.map(session => (
        <DataGrid.Row key={session.sessionId}>
          <DataGrid.Cell>
            <a href={uiRoutes.record(session.sessionId)} target="_blank" rel="noopener noreferrer">
              {session.title}
            </a>
          </DataGrid.Cell>
          <DataGrid.Cell>-</DataGrid.Cell>
          <DataGrid.Cell>
            {session.activeCount}
          </DataGrid.Cell>
          <DataGrid.Cell>
            {session.completeCount}
          </DataGrid.Cell>
          <DataGrid.Cell actions>
            <Link to={`/admin/session/${session.sessionId}`}>Details</Link>
          </DataGrid.Cell>
        </DataGrid.Row>
      ))}
    </DataGrid>
  )
}

const Sessions = () => {
  const user = useAuthentication("admin");
  const fetch = useApiFetch();

  const triggerCleanup = useCallback(() => {
    fetch(apiRoutes.triggerCleanup, {method: "POST"})
  }, [fetch]);

  if (!user.token) {
    return <Loading big>Loading...</Loading>
  }

  return (
    <>
      <h2>Sessions</h2>
      <SessionsGrid />
      <Link className="button button--inline" to="/admin/addsession">Create a new session</Link>
      <h2>Maintenance</h2>
      <button onClick={triggerCleanup}>Cleanup</button>
    </>
  )

}

export default Sessions;