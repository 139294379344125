import React, { useRef, useEffect, useCallback } from 'react';

const AudioContext = window.AudioContext || window.webkitAudioContext;

export const ClapContext = React.createContext();
const THRESHOLD = 4;
const INTERVAL = 10;

const DetectClap = ({mediaStream, children}) => {
  const callbacks = useRef([]);
  const onClap = useCallback(cb => callbacks.current = [...callbacks.current, cb], []);
  const notifyClap = useCallback(() => {
    callbacks.current.forEach(cb => cb());
  }, []); 
  
  useEffect(() => {
    const audioCtx = new AudioContext();
    const source = audioCtx.createMediaStreamSource(mediaStream);
    const analyser = audioCtx.createAnalyser();
    source.connect(analyser);
    analyser.fftSize = 32;
    let midClap = true;
    let lastVal = Infinity;

    const interval = setInterval(() => {
      const array = new Uint8Array(16);
      analyser.getByteTimeDomainData(array);
      const rawValue = Math.max(...array) || Infinity;
      if (rawValue > lastVal + THRESHOLD && !midClap) {
        midClap = true;
        setTimeout(() => midClap = false, 300);
        notifyClap();
      }
      lastVal = rawValue
    }, INTERVAL);

    // initial warm-up
    setTimeout(() => midClap = false, 500);

    return () => {
      clearInterval(interval);
      audioCtx.close();
    }      
  }, [mediaStream, notifyClap]);

  return (
    <ClapContext.Provider value={{onClap}}>
      {children}
    </ClapContext.Provider>
  )
}

export default DetectClap;
