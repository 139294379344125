const host = process.env.REACT_APP_HOST;

export default {
  session: host+"/session",
  adminSession: host+"/admin/session",
  sessionDetails: id => `${host}/session/${id}`,
  sessionNote: id => `${host}/session/${id}/note`,
  sessionInvites: id => `${host}/session/${id}/invites`,
  remix: id => `${host}/session/${id}/remix`,
  triggerMozaic: id => `${host}/session/${id}/triggerVideoGeneration`,
  trackBreakdown: id => `${host}/session/${id}/trackbreakdown`,
  tracksForSession: id => `${host}/session/${id}/tracks`,
  swapTracks: (id) => `${host}/session/${id}/swaptrack`,
  userProfile: `${host}/user`,
  mergeUsers: `${host}/user/merge`,
  trackDetails: (sessionId, trackId) => `${host}/session/${sessionId}/track/${trackId}`,
  stream: id => `${host}/session/${id}/stream.m3u8`,
  backingTrackStream: id => `${host}/session/${id}/backingTrack.m3u8`,
  quevideoStream: id => `${host}/session/${id}/queuevideo.m3u8`,
  mozaicStream: (id, share) => `${host}/session/${id}/mozaic.m3u8${share ? `?share=${encodeURIComponent(share)}` : ''}`,
  shareableLink: id => `${host}/session/${id}/shareable`,
  still: id => `${host}/file/still__${id}.jpg`,
  triggerCleanup: `${host}/maintenance/cleanup`,
  isInvited: id => `${host}/session/${id}/invited`,
  closeSession: id =>`${host}/session/${id}/close`,
  log: `${host}/log`,
}