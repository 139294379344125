import React, { useState, useEffect } from 'react';
import { useAuthenticationState } from './Authentication';
import useApiFetch from './useApiFetch';
import apiRoutes from './apiRoutes';
import Error from './Error';
import ChatLink from './ChatLink';
import Loading from './Loading';
import { Link, Redirect, useLocation } from 'react-router-dom';
import './UserProfile.css';
import InlineCta from './InlineCta';
import ReactModal from 'react-modal';
import { toAge, toTimestamp } from './util/time'
import CopyLink from './CopyLink';
import Banner from './Banner';
import DataGrid from './DataGrid';

const UserProfile = () => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const location = useLocation();

  const [ modalData, setModalData ] = useState();

  const userState = useAuthenticationState();
  const fetch = useApiFetch();

  useEffect(() => {
    if (userState === useAuthenticationState.ANONYMOUS || userState === useAuthenticationState.SIGNED_IN) {
      fetch(apiRoutes.userProfile)
        .then(res => res.json())
        .then(data => setData(data))
        .catch(error => setError(error));
    }
  }, [fetch, userState]);

  if (userState === useAuthenticationState.SIGNED_OUT) {
    return <Redirect to="/" />
  } 

  if (error) {
    return (
      <Error>
        <Error.Title>
          Something went wrong
        </Error.Title>
        <Error.Message>
          Could not retrieve your profile details. If this issue persists, please <ChatLink>get in touch</ChatLink>.
        </Error.Message>
      </Error>
    )
  }

  if (!data || userState === useAuthenticationState.INITIALISING) {
    return <Loading big>Loading...</Loading>
  }

  return (
    <>
      <h2>My recordings</h2>
      {userState === useAuthenticationState.ANONYMOUS && (
        <Banner warning>
          <Banner.Heading>
            You&apos;re using a guest account
          </Banner.Heading>
          <p>To make sure you don&apos;t lose access to your recordings, we recommend that you create a a free user account.</p>
          <Link className="button button--inline" to={`/signin?to=${encodeURIComponent(location.pathname + location.search)}`}>
            Sounds good. Let&apos;s do it!
          </Link>
        </Banner>
      )}
      {!data.length ? (
        <p>You haven&apos;t completed a recording session yet. <Link to="/sessions">Find a session</Link> to get started.</p>
      ) : (
        <>
          <p>
            This is a list of of the sessions you participated in. You can listen to the recordings and modify your part.
          </p>
          <DataGrid>
            {data.map(x => (
              <DataGrid.Row key={x.trackId}>
                <DataGrid.Cell hiddenOnMobile>
                  {x.createdTicks ? (
                    <abbr title={toTimestamp(x.createdTicks)}>{toAge(x.createdTicks)} ago</abbr>
                  ) : "Recently"}
                </DataGrid.Cell>
                <DataGrid.Cell>
                  You contributed {x.part.match(/^[aeio]/i) ? "an" : "a"} {x.part} part to <b>{x.title}</b>
                  <DataGrid.Details>
                    {x.createdTicks && (
                      <DataGrid.OnSmallScreen>
                        <li>You participated <b>{toAge(x.createdTicks)}</b> ago</li>
                      </DataGrid.OnSmallScreen>
                    )}
                    {x.trackCount === 1 && <li><b>1</b> participant so far</li>}
                    {x.trackCount > 1 && <li><b>{x.trackCount}</b> participants so far</li>}
                    {x.approximateBaseline < 10 && <li><span role="img" aria-label="">&#11088;</span> You were one of the first participants &ndash; awesome!</li>}
                  </DataGrid.Details>
                </DataGrid.Cell>
                <DataGrid.Cell actions>
                  <Link className="button" to={`/sessions/${x.sessionId}/stream?rtr=${encodeURIComponent(x.trackId)}`}>
                    Listen
                  </Link>
                  {x.allowSharing && (
                    <button className="button--link" onClick={() => setModalData(x)}>
                      Invite others
                    </button>
                  )}
                </DataGrid.Cell>
              </DataGrid.Row>
            ))}
          </DataGrid>
          <InlineCta>
            <InlineCta.Question>...ready for more?</InlineCta.Question>
            <InlineCta.Answer>
              <Link className="button" to="/sessions">Join a session</Link>
            </InlineCta.Answer>
          </InlineCta>
          
          <ReactModal
            isOpen={!!modalData}
            contentLabel="Invite your friends to participate"
            style={{
              content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: "80%",
                height: "280px",
                maxWidth: "600px",
                borderRadius: "15px",
                boxShadow: "3px 3px 5px 0px #5945ba",
                MozBoxShadow: "3px 3px 5px 0px #5945ba",
                WebkitBoxShadow: "3px 3px 5px 0px #5945ba"
              }
            }}
          >
            <h3>Invite participants to {modalData && modalData.title}</h3>
            <p style={{height: "100px"}}>Copy the below link and share it with your friends to invite them to the {modalData && modalData.title} session.</p>
            <p>
              <CopyLink value={`${window.location.protocol}//${window.location.host}/record/${modalData && modalData.sessionId}`} />
            </p>
            <button className="userProfile__close button--link" onClick={() => setModalData(null)}>Close</button>
            <div className="clearfix" />
          </ReactModal>
        </>
      )}
    </>
  )
}

export default UserProfile;