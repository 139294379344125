import React, { useMemo, useState } from 'react';
import ScanableWidth from '../ScanableWidth';
import CalibrateBySound from '../calibration/CalibrateBySound';
import DetectClap from '../calibration/DetectClap';
import { ReactComponent as Hear } from '../hear.svg';

const useSoundCalibrationPage = ({mediaStream, videoData = {}, audioOffset, setAudioOffset}) => {
  const [provisionalOffset, setProvisionalOffset] = useState(null);
  const [active, setActive] = useState(false);
  const includesCameraStep = videoData.videoMode !== 0;

  const element = useMemo(() => {
    if (audioOffset === null) {
      return (
        <ScanableWidth>
          <h3>{includesCameraStep ? "4." : "3."} Calibrate your microphone by sound</h3>
          <p>Clap <span role="img" aria-label="">&#128079;</span> in time with the downbeat (the high note) &ndash; this helps us detect your speaker delay.</p> 
          <button disabled={active || provisionalOffset !== null} onClick={() => setActive(true)}>Begin calibration</button>
          <Hear style={{width: "150px", fill: "#5945ba", height: "150px", margin: "2em", display: "block"}}/>
          <DetectClap mediaStream={mediaStream}>
            <CalibrateBySound active={active} onFinish={offset => {
              setProvisionalOffset(offset);
              setActive(false);
            }} />
          </DetectClap>
          {provisionalOffset && !active && (
            <>
              <p>
                You&apos;ve calibrated your speaker delay.
                Your {includesCameraStep ? "camera, microphone and speakers" : "microphone and speakers"} are 
                all set up and ready for you to make music!
              </p> 
              <button onClick={() => setAudioOffset(provisionalOffset)}>Continue</button>
              <p>
                <button className="button--link" onClick={() => setProvisionalOffset(null)}>Wait &ndash; I&apos;d like to try again!</button>
              </p>
            </>
          )}
        </ScanableWidth>
      )
    }

    return null;
  }, [mediaStream, audioOffset, includesCameraStep, setAudioOffset, active, provisionalOffset]);

  return [ element ];  
}

export default useSoundCalibrationPage;