import React, { useEffect } from "react"
import { Link } from "react-router-dom";
import apiRoutes from "./apiRoutes"

const DownloadSession = ({sessionId}) => {
  useEffect(() => {
    window.location = [apiRoutes.session, sessionId, "download"].join("/"); 
  }, [sessionId]);

  return (
    <>
      <h2>Downloading mp3...</h2>
      <p>This may take a minute to generate, go make some tea...</p>
      <p>
        <Link to="/">Back</Link>
      </p>
    </>
  );
}

export default DownloadSession;