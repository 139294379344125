import React from 'react';
import './InlineCta.js';

const InlineCta = ({children}) => (
  <div className="home__cta2">
    {children}
  </div>
)

InlineCta.Question = ({children}) => (
  <div className="home__cta2question">{children}</div>
)

InlineCta.Answer = ({children}) => (
  <div className="home__cta2answer">
    {children}
  </div>
);

export default InlineCta;