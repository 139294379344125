import { useContext, useEffect, useState, useMemo } from 'react';
import { ClapContext } from './DetectClap';
import SampleSet from '../util/SampleSet';
import useProgressBar from '../useProgressBar';

const CYCLE_TIME_MS = 1700;
const REQUIRED_SAMPLE_COUNT = 16;

const CalibrateBySound = ({onFinish, active}) => {
  const { onClap } = useContext(ClapContext);
  const [ts, setTs] = useState(null);
  const [soundTs, setSoundTs] = useState(null);
  const sampleSet = useMemo(() => new SampleSet(), []);
  const [setProgress, progress] = useProgressBar("Progress:");

  useEffect(() => {
    onClap(() => setTs(new Date().valueOf()));
  }, [onClap]);

  useEffect(() => {
    if (!active) {
      return;
    }
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  
    // create Oscillator node
    const oscillator = audioCtx.createOscillator();
    oscillator.type = 'square';
    oscillator.start();
    const gain = audioCtx.createGain();
    gain.gain.setValueAtTime(0, audioCtx.currentTime);
    oscillator.connect(gain);
    gain.connect(audioCtx.destination);

    const beep = freq => {
      oscillator.frequency.setValueAtTime(freq, audioCtx.currentTime); // value in hertz
      gain.gain.setValueAtTime(0.05, audioCtx.currentTime);
      gain.gain.setValueAtTime(0, audioCtx.currentTime + 0.1);
    }

    let i = 0;
    const interval = setInterval(() => {
      beep(i % 4 ? 500 : 1000);
      if (i % 4 === 0) {
        setSoundTs(new Date().valueOf())
      }
      i++;
    }, Math.floor(CYCLE_TIME_MS / 4));

    return () => {
      clearInterval(interval);
      audioCtx.close();
    }
  }, [active]);

  useEffect(() => {
    if (active) {
      setProgress(0);
      setSoundTs(null);
      setTs(null);
      sampleSet.clear();
    }
  }, [active]) //eslint-disable-line

  useEffect(() => {
    if (soundTs === null || ts === null || !active) {
      return;
    }
    if (ts - soundTs >= -0.1 * CYCLE_TIME_MS && ts - soundTs <= 0.7 * CYCLE_TIME_MS) {
      sampleSet.push(ts - soundTs);
      setProgress(sampleSet.getCount() / REQUIRED_SAMPLE_COUNT)
      if (sampleSet.getCount() >= REQUIRED_SAMPLE_COUNT) {
        onFinish(sampleSet.getMedian());
      }
    }
  }, [ts, soundTs, sampleSet, setProgress, onFinish, active])

  return progress 
}

export default CalibrateBySound;