import React, { useRef, useEffect, useState, useCallback, useContext } from 'react';
import Hls from 'hls.js';
import { detect as detectBrowser } from 'detect-browser';
import RangeInput from './RangeInput';
import './SynchronisedPlayback.css';
import { AuthContext, useAuthenticationState } from './Authentication';
import { useUploadContribution } from './UploadContribution';

const TOLERANCE = 0.05; /* todo: tighten this */
const SCAN_TOLERANCE = 1.5;

const SynchronisedPlaybackContext = React.createContext();

const SynchronisedPlayback = ({getCurrentTimestamp, offset = 0, children}) => {
  const callbacks = useRef([]);
  const onTimeUpdate = useCallback(cb => {
    callbacks.current.push(cb);
    return {
      unsubscribe: () => callbacks.current = callbacks.current.filter(x => x !== cb)
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const target = getCurrentTimestamp();
      const effectiveTarget = target === null ? null : target + offset;
      callbacks.current.forEach(cb => cb(effectiveTarget));        
    }, TOLERANCE * 1000 / 2);
    return () => clearInterval(interval);
  }, [getCurrentTimestamp, offset])

  return (
    <div className="synchronisedPlayback">
      <SynchronisedPlaybackContext.Provider value={{onTimeUpdate}}>
        {children}
      </SynchronisedPlaybackContext.Provider>
    </div>
  ) 
}


const makeTimestampUpdater = () => {
  if (detectBrowser().name !== "safari") {
    return (ref, targetTimestamp) => {
      const current = ref.current.currentTime;
    
      if (Math.abs(targetTimestamp - current) > SCAN_TOLERANCE) {
        // way off - skip to the correct time code
        ref.current.currentTime = Math.max(targetTimestamp, 0);
        if (ref.current.paused) {
          ref.current.play();
        }
      } else {
        // Play. 
        // If we're behind, speed up to catch up
        // If we're ahead, slow down for others to catch up.
        // (This is more effective than skipping, which causes a playback gap)
        const desiredPlaybackRate =
          Math.abs(targetTimestamp - current) < TOLERANCE ? 1 :
          targetTimestamp > current ? 1.5 : 0.5;
        
        if (ref.current.playbackRate !== desiredPlaybackRate) {
          ref.current.playbackRate = desiredPlaybackRate;
        }
    
        if (ref.current.paused) {
          ref.current.play();
        }
      }
    }
  }

  let safariSkipAhead = 0;
  let inFlightSafariProbe;
  let updateSafariSkipAhead = false;

  return (ref, targetTimestamp) => {
    const current = ref.current.currentTime;

    if (Math.abs(current - targetTimestamp) > SCAN_TOLERANCE + safariSkipAhead) {
      // we seem to have skipped - this invalidates any probe
      // in flight, so cancel it
      if (inFlightSafariProbe) {
        clearTimeout(inFlightSafariProbe);
        inFlightSafariProbe = undefined;
      }
      updateSafariSkipAhead = false;
    }

    if (inFlightSafariProbe) {
      // probe is in flight - let it play out
      return;
    }

    if (updateSafariSkipAhead) {
      // probe is ready - update our skip-ahead guess based on
      // how far we were off the mark.
      safariSkipAhead = Math.max(0, safariSkipAhead + targetTimestamp - current);
      updateSafariSkipAhead = false;
    }
  
    if (Math.abs(current - targetTimestamp) > TOLERANCE) {
      // we're not in time. Skip to a point near the target
      // and launch a probe to see if we end up on target 
      ref.current.currentTime = Math.max(targetTimestamp + safariSkipAhead, 0);
    
      inFlightSafariProbe = setTimeout(() => {
        updateSafariSkipAhead = true;
        inFlightSafariProbe = undefined;
      }, Math.max(300, safariSkipAhead * 1000));
    }

    if (ref.current.paused) {
      ref.current.play();
    }
  }
}

const SynchronisedPlaybackAudio = ({src, label = "Volume", initialVolume = 1, controls}) => {
  const ref = useRef();
  const [ready, setReady] = useState(false);

  const { onTimeUpdate } = useContext(SynchronisedPlaybackContext);
  const { tokenUser } = useContext(AuthContext);
  const userState = useAuthenticationState();
  const [token, setToken] = useState("");
  const { cork } = useUploadContribution();

  useEffect(() => {
    if (tokenUser) {
      tokenUser.getIdToken().then(t => setToken(t));
    }
  }, [tokenUser]);

  useEffect(() => {
    const updater = makeTimestampUpdater();
    const subscription = onTimeUpdate(targetTimestamp => {
      if (!ready) {
        return;
      }

      if (targetTimestamp === null || targetTimestamp < 0) {
        // paused or not ready
        if (!ref.current.paused) {
          ref.current.pause();
        }
      } else {
        updater(ref, targetTimestamp);
      }
    })

    return () => subscription.unsubscribe();
  }, [onTimeUpdate, ready]);
    
  useEffect(() => {
    if (!Hls.isSupported() 
      || (userState === useAuthenticationState.INITIALISING) 
      || (userState === useAuthenticationState.SIGNED_IN && !token)
    ) {
      return; 
    }
    
    const hls = new Hls({
      startPosition:0,
      manifestLoadingMaxRetry: 4,
      xhrSetup: xhr => xhr.setRequestHeader("Authorization", `Basic ${token}`)
    });
    hls.loadSource(src);
    hls.attachMedia(ref.current);
    let uncorker;
    hls.on(Hls.Events.BUFFER_APPENDED, (_event, data) => {
      const length = data.timeRanges.audio.length;
      if (length) {
        const timeLeft = data.timeRanges.audio.end(length - 1) - ref.current.currentTime;
        // console.log('timeleft (audio)', timeLeft);
        if (timeLeft < 10 && !uncorker) {
          uncorker = cork();
        } else if (timeLeft >= 10 && uncorker) {
          uncorker();
          uncorker = null;
        }
      }
    });
    hls.on(Hls.Events.MANIFEST_PARSED, function() {
      setReady(true);
    });

    return () => {
      if (hls) {
        // hls.detachMedia();
        hls.destroy();
      }
      if (uncorker) {
        uncorker();
      }
      setReady(false)
    }
  }, [src, ref, userState, token, cork]);

  const setVolume = useCallback(e => {
    if (ref.current) ref.current.volume = e.target.value;
  }, []);

  useEffect(() => {
    if (ref.current) ref.current.volume = initialVolume
  }, [initialVolume]);

  return (
    <>
      {controls && 
        <>
          <div className="synchronisedPlayback__label">{label || "Volume"}:</div>
          <RangeInput min={0} max={1} step={0.01} defaultValue={initialVolume} onChange={setVolume} /> 
        </>
      }
      <audio ref={ref} />
    </>
  ) 
}

SynchronisedPlayback.Audio = SynchronisedPlaybackAudio;

export default SynchronisedPlayback;