import React, { useState, useCallback } from 'react';
import extractData from './util/extractData';
import routes from './apiRoutes';
import Form from './Form';
import useApiFetch from './useApiFetch';

const AddNewSession = ({onClose}) => {
  const [submitting, setSubmitting] =  useState(false);
  const fetch = useApiFetch();

  const handleData = useCallback((data) => {
    if (!data.name) {
      alert("Please specify a name");
      return;
    }
    if (!data.host) {
      alert("Please specify the name of your ensemble or group");
      return;
    }
    // const match = data.url.match(/www.youtube.com\/watch\?v=([^&#]+)/)
    // if (!match) {
    //   alert("This doesn't seem to be a valid YouTube video URL");
    //   return;
    // }
    
    if (Number.isNaN(parseInt(data.live))) {
      alert("Please specify if the video is pre-recorded or a live broadcast");
      return;
    }

    const parts = data.parts.split("\n").map(x => x.trim()).filter(x => !!x);

    if(!parts.length) {
      alert("Please specify the parts for this piece of music");
      return;
    }

    setSubmitting(true)

    const body = new FormData();
    // body.append('videoId', match[1]); 
    body.append('title', data.name); 
    body.append('sheetUrl', data.sheet);
    body.append('description', data.description);
    body.append('host', data.host);
    body.append('isLive', data.live);
    body.append('isPublic', data.public && data.public[0] ? "1" : "0");
    body.append('allowAnonymous', data.allowAnonymous && data.allowAnonymous[0]  ? "1" : "0");
    body.append('allowMultiple', data.allowMultiple && data.allowMultiple[0]  ? "1" : "0");
    body.append('allowSharing', data.allowSharing && data.allowSharing[0]  ? "1" : "0");
    body.append('videoMode', (data.video && parseInt(data.video[0])) || "0");
    body.append('parts', JSON.stringify(parts));
    
    body.append('videoFile', data.videoFile[0])

    fetch(routes.session, {
      method: "POST",
      body: body   
    }).catch(e => alert(`encountered error: ${e.message}`))
      .then(() => {
        setSubmitting(false);
        if (onClose) {
          onClose();
        }
      });
  }, [onClose, fetch])

  const cancel = useCallback(e => {
    e.preventDefault();
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const DEFAULT_PARTS = "Soprano\nAlto\nTenor\nBass";

  return (
    <>
      <h2>Create a new session</h2>
      <Form onSubmit={extractData(handleData)}>
        <label htmlFor="name">
          Give a short title for this practice session
        </label>
        <input placeholder="e.g. Tallis: If ye love me, take 1" name="name" id="name" type="text" />
        <label htmlFor="host">
          What&apos;s the name of your ensemble or group?
          <span className="form__hint">This will appear under "Hosted by" in your public listing</span>
        </label>
        <input name="host" id="host" type="text" />
        <label htmlFor="description">
          Give a short description of what this session is about (optional)
        </label>
        <textarea name="description" id="description" rows={4} />
        <label htmlFor="sheet">
          Provide a link to the sheet music (optional)
        </label>
        <input placeholder="e.g. IMSLP" name="sheet" id="sheet" type="text" />
        {/* <label htmlFor="url">
          Paste the YouTube video URL here
          <span className="form__hint">This will be shown to participants during their performance and could be a backing track or a video of you conducting</span>
        </label>
        <input placeholder="e.g. https://www.youtube.com/watch?v=eqt005j1dB0" name="url" id="url" type="text" /> */}
        <label htmlFor="videoFile">
          Upload the conductor video
          <span className="form__hint">This will be shown to participants during their performance</span>
        </label>
        <input name="videoFile" id="videoFile" type="file" />
        <label htmlFor="parts">
          Specify the parts of this piece of music, one per row
        </label>
        <textarea name="parts" id="parts" rows={4} defaultValue={DEFAULT_PARTS} />
        <fieldset>
          <legend>
            This video is:
          </legend>
          <label>
            <input type="radio" name="live" value="0" />
            Pre-recorded
          </label>
          <label>
            <input type="radio" name="live" value="1" />
            A live broadcast
          </label>
        </fieldset>
        <fieldset>
          <legend>
            Make this session public?
            <span className="form__hint">Anyone can join public sessions, while private sessions need a secret participation link</span>
          </legend>  
          <label>
            <input type="checkbox" name="public" value="1"/>
            Yes, make this session public
          </label>
        </fieldset>
        <fieldset>
          <legend>
            Allow anonymous participants?
            <span className="form__hint">Disable this to require your participants to have an account on Durufle</span>
          </legend>  
          <label>
            <input type="checkbox" name="allowAnonymous" value="1"/>
            Yes, allow anonymous participants
          </label>
        </fieldset>
        <fieldset>
          <legend>
            Allow participants to create multiple entries?
            <span className="form__hint">By default, every participant will only be allowed to record one track in each session. You can allow multiple entries to enable over-dubbing.</span>
          </legend>  
          <label>
            <input type="checkbox" name="allowMultiple" value="1"/>
            Yes, allow multiple entries
          </label>
        </fieldset>
        <fieldset>
          <legend>
            Allow participants to share the session participation link?
            <span className="form__hint">
              If enabled, anyone with the participation link can 
              contribute to the session; otherwise, participants 
              need to be explicitly invited. <b>Note:</b> all participants
              will be able to see and share the resulting recording, regardless
              of this setting.
            </span>
          </legend>  
          <label>
            <input type="checkbox" name="allowSharing" value="1"/>
            Yes, allow participants to share the participation link
          </label>
        </fieldset>
        <fieldset>
          <legend>
            Enable video for this session?
            <span className="form__hint">This will allow you to export a music video with all your participants</span>
          </legend>  
          <label>
            <input type="checkbox" name="video" value="1"/>
            Yes, enable video for this session
          </label>
        </fieldset>

        <button disabled={submitting}>Create new session</button>
        <button className="button--link" onClick={cancel}>Cancel</button>
      </Form>
    </>
  )
}

export default AddNewSession;