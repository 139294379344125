export default function SampleSet(initial = []) {
  let samples = [...initial];
  let sum = samples.reduce((a,b) => a+b, 0);
  let sumOfSquares = samples.reduce((a,b) => a + b*b, 0);

  const getSorted = () => {
    const sorted = [...samples];
    sorted.sort((a,b) => a-b);
    return sorted;
  }

  this.push = (s) => {
    samples.push(s);
    sum += s;
    sumOfSquares += s*s;
  }

  this.clear = () => {
    samples = [];
    sum = 0;
    sumOfSquares = 0;
  }

  this.getCount = () => {
    return samples.length;
  }

  this.getMedian = () => {
    if (samples.length < 1) {
      return 0;
    }
    const sorted = getSorted();

    return sorted.length % 2
      ? sorted[Math.floor(sorted.length / 2)]
      : (sorted[sorted.length / 2 - 1] + sorted[sorted.length / 2]) / 2
  }

  this.getAverage = () => {
    return sum / samples.length;
  }

  this.getStandardDeviation = () => {
    if (samples.length < 2) {
      return Infinity
    }

    return Math.sqrt(
      (sumOfSquares + sum*sum/samples.length) / (samples.length - 1)
    );
  }

  this.getInterQuartileDeviation = () => {
    if (samples.length < 4) {
      return this.getStandardDeviation();
    }
    const sorted = getSorted();
    const lower = Math.floor(sorted.length / 4)
    const upper = Math.floor(3 * sorted.length / 4);

    return new SampleSet(sorted.slice(lower, upper)).getStandardDeviation();
  }
}