import { RecordRTCPromisesHandler } from 'recordrtc';

export default (socket) => { 
  let recorder, trackId, videoRef;

  async function start(_trackId, preExistingMediaStream, _videoRef, getCurrentTimestamp) {
      let startTime, startTime2

      if (!_trackId) {
        throw new Error("trackId required");
      }

      trackId = _trackId;

      videoRef = _videoRef;
      if (videoRef && 'srcObject' in videoRef) {
        videoRef.srcObject = preExistingMediaStream;
      } else if (videoRef) {
        // Avoid using this in new browsers, as it is going away.
        videoRef.src = URL.createObjectURL(preExistingMediaStream);
      }
      
      recorder = new RecordRTCPromisesHandler(preExistingMediaStream, {
        type: 'video',
        video: videoRef,
        timeSlice: 1000,
        ondataavailable: function upload(blob) {
          socket.emit('videodata', {
            trackId,
            startTime,
            startTime2,
            currentTime: getCurrentTimestamp()
          }, blob)
        }
      });
      
      const interval = setInterval(() => {
        const mediaTimestamp = getCurrentTimestamp();
        if (mediaTimestamp !== null) {
          clearInterval(interval);
          startTime = mediaTimestamp;
          recorder.startRecording().then(() => {
            startTime2 = getCurrentTimestamp()
          });
        }
      }, 10)
  }

  async function stop() {
    await recorder.stopRecording();
    if (videoRef && 'srcObject' in videoRef) {
      videoRef.srcObject = null;
    } else if (videoRef) {
      // Avoid using this in new browsers, as it is going away.
      videoRef.src = "";
    }

  }

  return {start, stop};
}