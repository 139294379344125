import React, { useContext } from 'react';
import './Layout.css';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext, useAuthenticationState } from './Authentication';

import './UserControls.css';

const UserControls = () => {
  const { signInPath, signOut, user } = useContext(AuthContext);
  const userState = useAuthenticationState();
  const location = useLocation();

  if (userState === useAuthenticationState.INITIALISING) {
    return null;
  }

  return (
    <ul className="userControls">
      {user && user.claims.name && (
        <li className="userControls__static">Hi, {user.claims.name}! </li>
      )} 

      {(user && user.claims.admin) && (
        <li>
          <Link className="userControls__action" to="/admin">
            Admin
          </Link>
        </li>
      )}

      {(userState === useAuthenticationState.ANONYMOUS || userState === useAuthenticationState.SIGNED_IN) && (
        <li>
          <Link className="userControls__action" to="/profile">
            My recordings
          </Link>
        </li>
      )}
      
      {(userState === useAuthenticationState.ANONYMOUS || userState === useAuthenticationState.SIGNED_OUT) && (signInPath !== location.pathname) && (
        <li>
          <Link className="userControls__action" to={`${signInPath}?to=${encodeURIComponent(location.pathname + location.search)}`}>
            Sign in
          </Link>
        </li>
      )}
      
      {(userState === useAuthenticationState.SIGNED_IN) && (
        <li>
          <button className="button--link userControls__action" onClick={signOut}>
            Sign out
          </button>
        </li>
      )}

    </ul>
  )
}

export default UserControls;