import React, { useMemo, useState } from 'react';
import ScanableWidth from '../ScanableWidth';
import DetectClap from '../calibration/DetectClap';
import CalibrateByVisual from '../calibration/CalibrateByVisual';

const useVisualCalibrationPage = ({mediaStream, videoData = {}, setVisualOffset, visualOffset}) => {
  const [provisionalOffset, setProvisionalOffset] = useState(null);
  const [active, setActive] = useState(false);
  const includesCameraStep = videoData.videoMode !== 0;

  const element = useMemo(() => {
    if (visualOffset !== null) {
      return null;
    }
    
    return (
      <ScanableWidth>
        <h3>{includesCameraStep ? "3." : "2."} Calibrate your microphone by sight</h3>
        {/* <p>Clap <span role="img" aria-label="">&#128079;</span> whenever the circle is full and the ring around it flashes &ndash; this helps us detect your microphone latency.</p>  */}
        <p>Clap <span role="img" aria-label="">&#128079;</span> whenever the ball hits the ground. This helps us detect delays in your microphone, so try and get your timing as precise as you can.</p> 
        <button disabled={active || provisionalOffset !== null} onClick={() => setActive(true)}>Begin calibration</button>
        <DetectClap mediaStream={mediaStream}>
          <CalibrateByVisual active={active} onFinish={offset => {
            setActive(false);
            setProvisionalOffset(offset);
          }} />
        </DetectClap>
        {provisionalOffset && !active && (
          <>
            <p>
              You&apos;ve calibrated your microphone delay. In the next
              and final step, we will calibrate your speaker delay.
            </p> 
            <button onClick={() => setVisualOffset(provisionalOffset)}>Proceed to final calibration step</button>
            <p>
              <button className="button--link" onClick={() => setProvisionalOffset(null)}>Wait &ndash; I&apos;d like to try again!</button>
            </p>
          </>
        )}
      </ScanableWidth>
    )
  }, [mediaStream, visualOffset, includesCameraStep, setVisualOffset, provisionalOffset, active]);

  return [ element ];  
}

export default useVisualCalibrationPage;