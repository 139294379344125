import React, { useEffect, useRef, useCallback, useState } from "react"
import useProgressBar from "./useProgressBar";

const DELAY = 1.5;

function useDelayedPlayback(mediaStream) {
  const [gain, setGain] = useState();

  useEffect(() => {
    var AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioCtx = new AudioContext();
    const source = audioCtx.createMediaStreamSource(mediaStream);

    const delay = audioCtx.createDelay(DELAY);
    delay.delayTime.value = DELAY;

    const gain = audioCtx.createGain();
    gain.gain.value = 0;

    source.connect(gain);
    gain.connect(delay);
    delay.connect(audioCtx.destination);

    setGain(gain);

    return () => {
      audioCtx.close();
    }
  }, [mediaStream])

  const setVolume = useCallback((vol) => {
    gain.gain.value = vol;
  }, [gain]);

  return setVolume;
}

function useMutuallyExclusiveTimeouts() {
  const timeouts = useRef([]);
  useEffect(() => () => timeouts.current.forEach(x => clearTimeout(x)), []);

  const setTimeouts = useCallback((newTimeouts = []) => {
    timeouts.current.forEach(x => clearTimeout(x));
    timeouts.current = [...newTimeouts];
  }, []);

  return setTimeouts; 
}

const MicTest = ({mediaStream}) => {
  const [inFlight, setInFlight] = useState(false);
  const setTimeouts = useMutuallyExclusiveTimeouts();
  
  const [setFraction1, progress1] = useProgressBar("Recording:");
  const [setFraction2, progress2] = useProgressBar("Playback:");

  const playback = useDelayedPlayback(mediaStream);

  const startRecording = useCallback(() => {
    let start = new Date().valueOf();
    setInFlight(true);
    const interval = setInterval(() => {
      const now = new Date().valueOf()

      setFraction1((now - start) / (DELAY * 1000));
      setFraction2((now - start - (DELAY * 1000)) / (DELAY * 1000));

      if (now - start > 100 + 2 * (DELAY * 1000)) {
        clearInterval(interval);
        setFraction1(0);
        setFraction2(0);
        setInFlight(false);
      }
    }, 50);

    const t1 = setTimeout(() => playback(1), 50)
    const t2 = setTimeout(() => playback(0), 1000 * DELAY)

    setTimeouts([t1, t2]);
  }, [playback, setTimeouts, setFraction1, setFraction2]);

  return (
    <>
      <p>To test that we can hear you, record yourself playing or siging something into your microphone &ndash; can you hear yourself back a second later?</p>
      <p><b>Tip:</b> <span role="img" aria-label="">&#x1F3A7;</span> Duruflé works best if you wear headphones!</p>
      <p>
        <button className="button--inline" disabled={inFlight} onClick={startRecording}>&#9210; Start recording</button>
      </p>
      <p>
        {progress1}
      </p>
      <p>
        {progress2}
      </p>
    </>
  );
}

export default MicTest;