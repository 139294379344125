import React, { useCallback, useState, useMemo } from 'react';
import ReactModal from 'react-modal';
import './useNotification.css';

const useNotification = ({suppressMultiple} = {}) => {
  const [shown, setShown] = useState(false);
  const [everShown, setEverShown] = useState(false);
  const [showDefaultCloseButton, setShowDefaultCloseButton] = useState(true);
  const [content, setContent] = useState(null);
  const [title, setTitle] = useState("");

  const show = useCallback((title, content) => {
    if (suppressMultiple && everShown) {
      return false;
    }
    
    const contentIsFunc = typeof content === "function";

    setShown(true);
    setEverShown(true);
    
    setContent(contentIsFunc ? content(() => setShown(false)) : content);
    setShowDefaultCloseButton(!contentIsFunc);
    setTitle(title);
  }, [suppressMultiple, everShown]);

  const element = useMemo(() => (
    <ReactModal
      isOpen={shown}
      contentLabel={title}
      style={{
        content : {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: "90%",
          maxWidth: "600px",
          borderRadius: "15px",
          boxShadow: "3px 3px 5px 0px #5945ba",
          MozBoxShadow: "3px 3px 5px 0px #5945ba",
          WebkitBoxShadow: "3px 3px 5px 0px #5945ba"
        }
      }}
    >
      <h3 className="useNotification__title">{title}</h3>
      {content}
      {showDefaultCloseButton && <button className="useNotification__close button--link" onClick={() => setShown(false)}>Close</button>}
    </ReactModal>
  ), [content, title, shown, showDefaultCloseButton]) 

  return [
    show,
    element
  ];
}

export default useNotification;