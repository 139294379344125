import React, { useState } from 'react';
import './Switch.css';

const Switch = ({onToggle, defaultValue, children}) => {
  const [on, setOn] = useState(defaultValue);

  return (
    <label className="switch__wrapper noselect">
      <div className="switch">
        <input type="checkbox" checked={!!on} onChange={e => {
          onToggle(!!e.target.checked);
          setOn(!!e.target.checked);
        }} />
        <span className="switch__slider"></span>
      </div>
      <div className="switch__contents">{children}</div>
    </label>
  )
}

export default Switch;