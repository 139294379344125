import React from 'react';
import logo from './durufle_layered.png';
import './Layout.css';
import { Link } from 'react-router-dom';
import UserControls from './UserControls';


const Layout = ({children}) => (
  <div className="layout__wrapper">
    <div className="layout__header">
      <UserControls />
      <Link to="/">
        <img className="layout__logo" src={logo} alt="Durufé - Make music anywhere" />
      </Link>
    </div>
    <div className="layout__mainwrap">
      {children}
    </div>
    <div className="layout__footer">
      &copy; {new Date().getFullYear()} Eigencode Ltd.
    </div>
  </div>
);

export default Layout;