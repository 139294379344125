import apiRoutes from "./apiRoutes";

export default function sendLog(fetch, level, messages, userId) {
  console[level](messages.join(" "));
  return fetch(apiRoutes.log, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      level,
      messages,
      userId: userId,
    })
  })
}