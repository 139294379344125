import React, { useState, useEffect, useMemo } from 'react';
import ScanableWidth from '../ScanableWidth';
import Switch from '../Switch';
import Banner from '../Banner';
import useApiFetch from '../useApiFetch';
import apiRoutes from '../apiRoutes';
import PerformanceNote from './PerformanceNote';

const useRecordingInstructionsPage = ({sessionId, videoData, breakdownData, part, shouldOverride}) => {
  const [complete, setComplete] = useState();
  const [hearParticipants, setHearParticipants] = useState();
  const [notes, setNotes]  = useState([]);
  const fetch = useApiFetch();
  
  // this acconts for whether you have deleted yourself from the recording at the start of the workflow
  const canHearParticipants = videoData && videoData.activeCount + videoData.completeCount - (shouldOverride ? 1 : 0) > 0;
  const totalCount = !videoData ? 0 : videoData.activeCount + videoData.completeCount - (shouldOverride ? 1 : 0);
  const myPartBreakdown = breakdownData && breakdownData.find(p => p.part === part);
  const myPartCount = myPartBreakdown ? myPartBreakdown.total - (shouldOverride ? 1 : 0) : 0;
  
  useEffect(() => {
    setHearParticipants(totalCount > 1);
  }, [totalCount])

  useEffect(() => {
    fetch(apiRoutes.sessionNote(sessionId))
      .then(x => x.json())
      .then(x => setNotes(x));
  }, [fetch, sessionId]);

  const element = useMemo(() => {
    if (complete) {
      return null;
    }

    const newNotes = notes.filter(x => x.isNew);
    const oldNotes = notes.filter(x => !x.isNew);
  
    return (
      <ScanableWidth>
        <h3>You're ready to start recording</h3>
          {shouldOverride && totalCount > 0 ? ( 
            <Banner warning>
              <p>
                <b>Note:</b> You will be able to hear your previous 
                take in the mix &ndash; it&apos;s still in there until
                you replace it with the one you&apos;re about to record.
              </p>
            </Banner>
          ) : null}
          {totalCount <= 4 && (
            <Banner>
              <p>
                <b style={{color: "#5945ba"}}>Important:</b> You are one of the first participants in this 
                session &ndash; <b>thank you</b> for kicking things off! Don&apos;t try to get a perfect recording
                done straight away &ndash; that&apos;s quite challenging without hearing many other
                participants. But your addition to the mix will <b>support other participants</b> making their 
                part great.  The <b>trick to using Duruflé</b> is to participate over and over: now to
                kick things off, and later to redo your part when there are more people in the mix.
              </p>
            </Banner>
          )}
          {notes.length ? (
            <>
              <h3>
                Performance notes
              </h3>
              <p>
                The organiser has provided the following notes for the recording so 
                far. Keep their feedback in mind during your performance:
              </p>
                {newNotes.map(x => <PerformanceNote key={x.id} note={x} />)}
                {oldNotes.length ? (
                  <details>
                    <summary style={{cursor: "pointer"}}><b>See older notes ({oldNotes.length})</b></summary>
                    {oldNotes.map(x => <PerformanceNote key={x.id} note={x} />)}
                  </details>
                ) : null}
            </>
          ) : null}
          {totalCount > 1 ? (
            <p>
              There are already <b>{totalCount} participants</b> in this session,{' '}
              {myPartCount > 0 ? "including some performing your part. " : "but you are the first one in your part. "} 
              Would you like to hear them in the backing track?
            </p>
          ) : totalCount === 1 ? (
            <>
            <p>
              There is <b>one other participant</b> in this session, 
              {myPartCount > 0 ? "and they are performing the same part as you. " : "and they are performing a different part to you. "}
              Would you like to hear them in the backing track?
            </p>
            </>
          ) : null}
          {canHearParticipants &&
            <Switch defaultValue={hearParticipants} onToggle={setHearParticipants}>Hear other participants</Switch>
          }
        <button onClick={() => setComplete(true)}>Got it. Let&apos;s go!</button>
      </ScanableWidth>
    )
  }, [totalCount, myPartCount, canHearParticipants, hearParticipants, complete, shouldOverride, notes]);

  return [element, {hearParticipants, canHearParticipants}];
}

export default useRecordingInstructionsPage;