import React, { useEffect, useRef, useState } from 'react';
import './VideoProgress.css';

const VideoProgress = React.forwardRef(({duration, getCurrentTimestamp}, ref) => {
  const containerRef = useRef();
  const rectRef = useRef()
  const [width, setWidth] = useState(0);
  const [percentage, setPercentage] = useState(null);
  const [showHint, setShowHint] = useState();

  useEffect(() => {
    if (!rectRef.current) {
      return;
    }
    const i = setInterval(() => {
      const ts = getCurrentTimestamp();
      setPercentage(ts && duration ? 100 * ts/duration : null);
    }, 500);
    return () => clearInterval(i);
  }, [rectRef, getCurrentTimestamp, duration])

  useEffect(() => {
    setWidth(Math.max(60, (containerRef.current && containerRef.current.clientWidth) || 60) );
  }, [containerRef]);


  if (percentage > 70 && !showHint) {
    setShowHint(true);
  }

  return (
    <div className="videoProgress" ref={containerRef}>
      <div className="videoProgress__svgwrap">
        <svg width={`${width}px`} height="5px" xmlns="http://www.w3.org/2000/svg">
          <rect ref={rectRef} x="0" y="0" fill="#5945ba" width={`${percentage || 100}%`} height="100%" />
        </svg>
      </div>
      <p className={`videoProgress__helpText ${showHint && "videoProgress__helpText--active"}`}>
        Allow the video to play to the end.
      </p>
    </div>
  );
});

export default VideoProgress;