import React, { useEffect, useMemo, useState } from 'react';
import { useUploadContribution } from '../UploadContribution';
import Loading from '../Loading';
import useProgressBar from '../useProgressBar';

const useSlowUploadPage = (_, isNextUp) => {
  const [complete, setComplete] = useState();

  const ws = useUploadContribution();
  const [setFraction, progressElement] = useProgressBar(<Loading>Uploading&hellip;</Loading>);

  useEffect(() => {
    if (ws && isNextUp) {
      const startLength = ws.getQueueLength();
      ws.uncork();

      const interval = setInterval(() => {
        const length = ws.getQueueLength();
        setFraction((startLength - length) / startLength);
        if (!length) {
          setComplete(true);
        }
      }, 200);
      return () => clearInterval(interval);
    }
  }, [ws, isNextUp, setFraction]);

  const element = useMemo(() => complete ? null : (
    <>
      <h3>
        Uploading your recording
      </h3>
      <p>
        Please wait for a minute while we upload your recording...
      </p>
      <p>
        {progressElement}
      </p>
    </>
  ), [complete, progressElement]);

  return [ element ];
}

export default useSlowUploadPage;
