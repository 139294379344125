import React, { useRef } from 'react';
import Helmet from 'react-helmet';

import useMultipageWizard from '../util/useMultipageWizard';

import useBrowserCheckPage from './useBrowserCheckPage';
import useLoadSessionDataPage from './useLoadSessionDataPage';
import usePracticeInstructionsPage from './usePracticeInstructionsPage';
import useAuthenticationPage from './useAuthenticationPage';
import useAcquireMediaStreamPage from './useAcquireMediaStreamPage';
import useCameraTestPage from './useCameraTestPage';
import useMicTestPage from './useMicTestPage';
import useReuseCalibrationPage from './useReuseCalibrationPage';
import useVisualCalibrationPage from './useVisualCalibrationPage';
import useSoundCalibrationPage from './useSoundCalibrationPage';
import useSetNamePage from './useSetNamePage';
import useSetPartPage from './useSetPartPage';
import useRecordingInstructionsPage from './useRecordingInstructionsPage';
import useRecorderPage from './useRecorderPage';
import useSlowUploadPage from './useSlowUploadPage';
import useConfirmDraftReplacementPage from './useConfirmDraftReplacementPage';
import useFinishedPage from './useFinishedPage';

const RecordingWorkflow = ({sessionId}) => {
  const ref = useRef();
  const [ wizardPage, data ] = useMultipageWizard([
    useLoadSessionDataPage,
    usePracticeInstructionsPage,
    useBrowserCheckPage,
    useAuthenticationPage,
    useSetNamePage,
    useSetPartPage,
    useAcquireMediaStreamPage,
    useCameraTestPage,
    useMicTestPage,
    useReuseCalibrationPage,
    useVisualCalibrationPage,
    useSoundCalibrationPage,
    useRecordingInstructionsPage,
    useRecorderPage,
    useSlowUploadPage,
    useConfirmDraftReplacementPage,
    useFinishedPage
  ], {sessionId}, {ref})
    
  const title = data && data.videoData && data.videoData.title;

  return (
    <>
      <Helmet>
        {title && <title>{title}</title>} 
      </Helmet>
      <div ref={ref}>
        {title && <h2>{title}</h2>}
        {wizardPage}
      </div>
    </>
  );
}

export default RecordingWorkflow;
