export default callback => e => {
  e.preventDefault();
  const inputs = e.target.querySelectorAll("input[type='radio']:checked, input[type='text'], input[type='date'], textarea");
  const file = e.target.querySelectorAll("input[type='file']");

  const checkboxes = e.target.querySelectorAll("input[type='checkbox']:checked");
  const checkboxValues = {};
  checkboxes.forEach(({name, value}) => {
    checkboxValues[name] = checkboxValues[name] || [];
    checkboxValues[name].push(value);
  })

  const inputValues = [...inputs].map(({name, value}) => ({[name]: value}));
  const fileValues = [...file].map(({name, files}) => ({[name]: files}));
  
  const values = Object.assign({}, checkboxValues, ...inputValues, ...fileValues);
  callback(values);
}