import React from 'react';
import './Error.css';

const Error = ({children}) => (
  <div className="error">
    <div className="error__banner">:-(</div>
    {children}
  </div>
)

Error.Title = ({children}) => (
  <h2 className="error__title">{children}</h2>
)

Error.Message = ({children}) => (
  <p className="error__message">{children}</p>
)

export default Error;