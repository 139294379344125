import React from 'react';
import './Banner.css';

const Banner = ({warning, children}) => (
  <div className={`banner ${warning ? "banner--warning" : ""}`}>
    {children}
  </div>
)

const BannerHeading = ({children}) => (
  <h3 className="banner__heading">
    {children}
  </h3>
)

Banner.Heading = BannerHeading;

export default Banner;