import React, { useState, useEffect } from 'react';
import apiRoutes from './apiRoutes';
import Error from './Error';
import Loading from './Loading';
import { Link } from 'react-router-dom';
import useApiFetch from './useApiFetch';

const UndoTrack = ({sessionId, trackId}) => {
  const [done, setDone] = useState(false);
  const [error, setError] = useState();
  const fetch = useApiFetch();
  
  useEffect(() => {
    fetch(apiRoutes.trackDetails(sessionId, trackId), {method: "DELETE"})
      .then(({ok}) => ok ? setDone(true) : setError("something went wrong"))
      .catch(e => setError(e));
  },[sessionId, trackId, fetch]);

  if (error) {
    return (
      <Error>
        <Error.Title>Could not remove part</Error.Title>
        <Error.Message>
          Your part could not be removed from the mix due to a technical issue. Please try again.
        </Error.Message>
      </Error>
    )
  }

  if (!done) {
    return (
      <>
        <h2>Removing part</h2>
        <Loading>Removing your part from the mix...</Loading>
      </>
    );
  }

  return (
    <>
      <h2>Removing part</h2>
      <p>Your part was successfully removed from the mix.</p>
      <p><Link to="/profile">Back to My recordings</Link>.</p>
    </>
  );
}

export default UndoTrack;