import React from 'react';
import './CopyLink.css';

const CopyLink = ({value}) => (
  <input 
    type="text" 
    className="copyLink__invite"
    readOnly  
    onClick={e => e.target.select()} 
    value={value} 
  /> 
)

export default CopyLink;