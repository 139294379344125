import { useContext, useMemo } from "react";
import useApiFetch from "./useApiFetch"
import { AuthContext } from "./Authentication";
import sendLog from "./sendLog";

export default function useLogger() {
  const { user } = useContext(AuthContext);
  const fetch = useApiFetch();

  const userId = user && user.claims ? user.claims.sub : null;

  const logger = useMemo(() => ({
    info: (...args) => sendLog(fetch, 'info', args, userId),
    warn: (...args) => sendLog(fetch, 'warn', args, userId),
    error: (...args) => sendLog(fetch, 'error', args, userId),
  }), [userId, fetch]);

  return logger;
}