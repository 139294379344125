import React from 'react';
import './DataGrid.css';

const DataGrid = ({children, compact}) => (
  <table className={`dataGrid ${compact ? "dataGrid--compact" : ""}`}>
    <tbody>
      {children}
    </tbody>
  </table>
)

const DataGridRow = ({children}) => <tr>{children}</tr>
DataGrid.Row = DataGridRow;

const DataGridCell = ({children, hiddenOnMobile, actions}) => (
  <td className={hiddenOnMobile ? "dataGrid__row--hiddenOnMobile" : actions ? "dataGrid__row--actions" : ""}>
    {children}
  </td>
)
DataGrid.Cell = DataGridCell;

const DataGridOnSmallScreen = ({children}) => 
  React.isValidElement(children) 
    ? React.cloneElement(children, {className: `${children.props.className || ""} dataGrid__showOnMobile`})
    : <span className="dataGrid__showOnMobile">{children}</span>
DataGrid.OnSmallScreen = DataGridOnSmallScreen;

const DataGridDetails = ({children}) => <ul className="dataGrid__details">{children}</ul>;
DataGrid.Details = DataGridDetails;

export default DataGrid;