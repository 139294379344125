import React, { useCallback, useState } from 'react';
import Form from './Form';
import useApiFetch from './useApiFetch';
import { useAuthentication } from './Authentication';
import apiRoutes from './apiRoutes';
import useNotification from './useNotification';
import { Redirect, Link } from 'react-router-dom';
import extractData from './util/extractData';

// https://emailregex.com/
// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const InviteParticipants = ({sessionId}) => {
  const [done, setDone] = useState(false);

  useAuthentication("admin");
  const fetch = useApiFetch();
  const returnPath = `/admin/session/${sessionId}`;
  const [notify, notificationElement] = useNotification();

  const submit = useCallback(({invites, deadline, description}) => {
    const emails = invites.split("\n").map(x => x.trim()).filter(x => !!x);
    const badEmail = emails.find(x => !x.match(emailRegex));
    
    // const saneDeadlineMatch = deadline.match(/^([0-9]+)\/([0-9]+)\/([0-9]+)$/)
    // console.log(deadline, saneDeadlineMatch);
    
    const saneDeadline = Date.parse(deadline);
    

    if (badEmail) {
      notify("Invalid email address", <p>The row <b>{badEmail}</b> is not a valid email address.</p>)
    } else if (deadline && !saneDeadline) {
      notify("Invalid date", <p>The deadline you have entered is not a valid date.</p>)
    } else {
      fetch(apiRoutes.sessionInvites(sessionId), {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          emails,
          deadline: saneDeadline,
          description: description || null
        })
      }).then(() => setDone(true))
    }
  }, [fetch, sessionId, notify])

  if (done) {
    return <Redirect push to={returnPath} />
  }

  return (
    <Form onSubmit={extractData(submit)}>
      <label for="invites">
        Participants to invite
        <span className="form__hint">Enter one email address per row</span>
      </label>
      <textarea id="invites" name="invites">
      </textarea>

      <label for="deadline">
        Submission date (optional)
        <span className="form__hint">Specify a target date for submissions to encourage timely participation</span>
      </label>
      <input type="date" placeholder="yyyy-mm-dd" id="deadline" name="deadline"/>

      <label for="description">
        Description (optional)
        <span className="form__hint">You can send your invitation with a message for the above participants</span>
      </label>
      <textarea id="description" name="description"/>

      <button>Submit</button>
      <p>
        <Link to={returnPath}>Cancel</Link>
      </p>
      {notificationElement}
    </Form>
  );
}

export default InviteParticipants;