import React from 'react';
import { toAge } from '../util/time';
import './PerformanceNote.css';

const PerformanceNote = ({note: {timestamp, body}}) => {
  return (
    <div className="performanceNote">
      <div className="performanceNote__timestamp">
        Posted {toAge(timestamp)} ago
      </div>
      {body.split("\n").filter(x => !!x).map(x => <p className="performanceNote__line" key={x}>{x}</p>)}
    </div>
  )
}

export default PerformanceNote;