export default class TrackIdManager {
  constructor (sessionId, socket, logger, metadata, onFinished) {
    this.sessionId = sessionId;
    this.metadata = metadata;
    this.onFinished = onFinished;
    this.socket = socket;
    this.logger = logger;
  }

  async getTrackId(isDraft) {
    const trackId = await this.socket.emitNow('requestTrackId', {
      ...this.metadata,
      sessionId: this.sessionId,
      isDraft: !!isDraft 
    });

    this.trackId = trackId;
    this.isDraft = isDraft;
    this.socket.reset();
    return trackId;
  }

  finish() {
    if (!this.trackId) {
      return;
    }
    const trackId = this.trackId;
    this.trackId = null;
    this.socket.emit('finishTrack', {
      sessionId: this.sessionId,
      trackId: trackId,
      isDraft: this.isDraft,
    })
    this.onFinished(trackId);
  }

  cancel() {
    if (!this.trackId) {
      // TrackIdManager can only cancel active tracks
      return;
    }
    this.socket.emitNow('cancel', {
      sessionId: this.sessionId,
      trackId: this.trackId
    });
    
    this.socket.reset();
    this.trackId = null;
    this.isDraft = undefined;
  }

  dispose() {
    this.cancel();
  }
}