import React, { useState, useMemo, useEffect } from 'react';
import extractData from '../util/extractData';
import Form from '../Form';

const DEFAULT_PART_NAME = "Participant";

const useSetPartPage = ({videoData}) => {
  const [part, setPart] = useState(null);

  const parts = videoData ? videoData.parts : [];

  useEffect(() => {
    if (videoData) {
      if (videoData.parts.length === 0) {
        setPart(DEFAULT_PART_NAME)
      } else if (videoData.parts.length === 1) {
        setPart(videoData.parts[0])
      }
    }
  }, [videoData]);

  const element = useMemo(() => {
    if (part === null) {
      return (
        <Form onSubmit={extractData(p => setPart(p.part))}>
          <fieldset>
            <legend>
              What part will you sing?
            </legend>
            {parts.map((part) => (
              <label key={part}>
                <input type="radio" name="part" value={part}/>
                {part}
              </label>
            ))}
            <button>Next</button>
          </fieldset>
        </Form>
      );
    }
    return null;
  }, [part, parts]);

  return [element, {part}];
}

export default useSetPartPage;