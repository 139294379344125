import React, { useState, useMemo, useEffect } from 'react';
import ScanableWidth from '../ScanableWidth';
import PracticeModal from '../PracticeModal';

import './usePracticeInstructionsPage.css';
import { Link } from 'react-router-dom';
import apiRoutes from '../apiRoutes';
import { useAuthenticationState } from '../Authentication';

const usePracticeInstructionsPage = ({sessionId, videoData = {}}) => {
  const [complete, setComplete] = useState(false);
  const [isThereSomethingToOverride, setIsThereSomethingToOverride] = useState(false);
  const [shouldOverride, setShouldOverride] = useState(false);
  const [showPracticeModal, setShowPracticeModal] = useState(false);

  const userState = useAuthenticationState();
  
  useEffect(() => {
    const toOverride = !!videoData.existingPart;
    if (isThereSomethingToOverride !== toOverride) {
      setIsThereSomethingToOverride(toOverride);
      setComplete(false);
    }
  }, [videoData, isThereSomethingToOverride]);

  const totalParticipants = videoData.activeCount + videoData.completeCount;
  
  // eslint-disable-next-line
  const element = useMemo(() => {
    if (!complete) {
      if (videoData.isClosed) {
        return (
          <ScanableWidth>
            <h3>This session is now finished</h3>
            <p>
              The organiser has closed this session and is no longer accepting
              new participants.
            </p>
          </ScanableWidth>
        )
      }

      return (
        <ScanableWidth>
          <p>In this session, you will see a video of a conductor directing <em>{videoData.title}</em>. Here is what it will look like: <img className="usePracticeInstructionsPage__preview" src={apiRoutes.still(sessionId)} alt="" /></p>
          {videoData.description ? (
            videoData.description.split("\n").filter(x => !!x).map(x => <p key={x}><em>&quot;{x}&quot;</em></p>)
          ) : null}
          <h3>How to prepare for this session</h3>
          <ol>
            <li>Find good <span role="img" aria-label="">&#x1F3A7;</span> headphones, and <b>wear them on one ear only</b> &ndash; this way you can hear the backing track as well as yourself.</li>
            <li>
              Have some <b>sheet music</b> ready.{' '}
              {videoData.sheetUrl && 
                <> 
                  If you don&apos;t own a copy, you can{' '}
                  <a href={videoData.sheetUrl} target="_blank" rel="noopener noreferrer">print the music from here</a>
                  .
                </>}
            </li>
            {!videoData.isLive && 
              <li>
                You may want to <b>practice</b> first. You could <button className="button--link" onClick={() => setShowPracticeModal(true)}>preview the conductor video</button> if you find this helpful.
              </li>
            }
            <li>
              Remember to <b>warm up</b> before performing.
            </li>
          </ol>
          <PracticeModal isOpen={showPracticeModal} title={videoData.title} sessionId={sessionId} onClose={() => setShowPracticeModal(false)} />

          {userState !== useAuthenticationState.INITIALISING && !isThereSomethingToOverride ? (
            <>
              <h3>Participate</h3>
              <p>
                Are you ready to add yourself to the mix? We will help you set up
                your <b>{videoData.videoMode !== 0 ? "camera, microphone and speakers" : "microphone and speakers"}</b>,
                and you can record your part by following the conductor video. 
              </p>
              {totalParticipants === 1 && 
                <p>
                  <b>One other person</b> has already participated in this session, and 
                  you will be able to hear their sound in the mix &ndash; so go make some music with them!
                </p>
              }
              {totalParticipants > 1 && 
                <p>
                  <b>{totalParticipants} other people</b> have already participated in this session, and 
                  you will be able to hear their sound in the mix &ndash; so go make some music with them!
                </p>
              }
              <button onClick={() => setComplete(true)}>Participate in this session</button>
            </>
          ) : userState !== useAuthenticationState.INITIALISING ? (
            <>
              <h3>You have already participated in this session</h3>
              <p>You have already contributed {videoData.existingPart && videoData.existingPart.match(/^[aeio]/i) ? "an" : "a"} {videoData.existingPart} part to this session. Do you want to remove your previous take before joining again?</p>
              <button onClick={() => {
                setShouldOverride(true);
                setComplete(true);
              }}>Yes, remove my existing part</button>
              <p>
                <b>Note:</b> You will be able to hear your previous recording in the backing track.
              </p>
              {videoData.allowMultiple && 
                <p>
                  If you want to contribute multiple parts to this session, your can{' '}
                  <button className="button--link" onClick={() => setComplete(true)}>record an additional part</button>.
                </p>
              }
              <p>
                <Link to="/profile">Go to My recordings</Link>.
              </p>  
            </>
          ) : null}
        </ScanableWidth>
      )
    };

    return null;
  })

  return [element, {shouldOverride}];
}

export default usePracticeInstructionsPage;
