import React, { useState, useMemo } from 'react';
import ChatLink from '../ChatLink';
import MicTest from '../MicTest';
import ScanableWidth from '../ScanableWidth';
import ScannableWidth from '../ScanableWidth';

const useMicTestPage = ({mediaStream, videoData}) => {
  const [micOk, setMicOk] = useState(null);

  const videoMode = videoData && videoData.videoMode;
  
  const element = useMemo(() => {
    if (micOk === null) {
      return (
        <ScannableWidth>
          <h3>{videoMode !== 0 ? "2. " : "1."} Test your microphone</h3>
          <MicTest mediaStream={mediaStream} onFinish={x => setMicOk(!!x)} />
          <p>
            If you can hear yourself, we will fine-tune your microphone next. 
          </p>
          <button onClick={() => setMicOk(true)}>Yes, I can hear myself &ndash; continue</button>
          <p>
            <button onClick={() => setMicOk(false)} className="button--link">Help! I can't hear myself</button>
          </p>
        </ScannableWidth>
      );
    }
  
    if (micOk === false) {
      return (
        <ScanableWidth>
          <p>Some tips that might help you:</p>
          <ul>
            <li>Try recording yourself with a recording app on your device</li>
            <li>If you have multiple recording devices connected, check that you've set your browser to use the correct one</li>
            <li>Check the volume levels on your system - your microphone or your speakers might be muted</li>
            <li>If all else fails, <ChatLink>message me</ChatLink></li>
          </ul>
        </ScanableWidth>
      )
    }

    return null;
  }, [mediaStream, videoMode, micOk]);

  return [element];
}

export default useMicTestPage;