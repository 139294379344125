import React from 'react';

import './SynchronisedVideoSender.css';
import Silhouette from './Silhouette';

const SynchronisedVideoSender = React.forwardRef((props, ref) => {
  return (
    <div className="synchronisedVideoSender">
      <video autoPlay muted ref={ref} className="synchronisedVideoSender__video" />
      <Silhouette />
    </div>
  );
})

export default SynchronisedVideoSender;
