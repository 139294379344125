import React, { useEffect, useState, useCallback, useRef } from 'react';
import RecordingSender from './RecordingSender';
import './Recorder.css';
import SynchronisedPlayback from './SynchronisedPlayback';
import useVideoPlayer from './useVideoPlayer';
import VideoProgress from './VideoProgress';
import Loading from './Loading';
import SynchronisedVideoSender from './SynchronisedVideoSender';
import TrackIdManager from './TrackIdManager';
import makeVideoSender from './video/videoSender2';
import useNotification from './useNotification';
import apiRoutes from './apiRoutes';
import useLogger from './useLogger';
import { useUploadContribution } from './UploadContribution';


const Recorder = ({sessionId, draftMode, canHearParticipants, hearParticipants, videoMode, mediaStream, metadata, onFinish, speakerOffset, micOffset}) => {
  const [trackManager, setTrackManager] = useState();
  const [audioSender, setAudioSender] = useState();
  const [videoSender, setVideoSender] = useState();
  const [started, setStarted] = useState();
  const [cancelCount, setCancelCount] = useState(0);
  const [playerDefaultsSet, setPlayerDefaultsSet] = useState(false);

  const webSocket = useUploadContribution();

  const playerRef = useRef();
  const loopBackVideoRef = useRef();
  const [VideoFrame, player, playing] = useVideoPlayer(apiRoutes.quevideoStream(sessionId), playerRef, true);

  const [showNotification, notificationElement] = useNotification();
  const logger = useLogger();

  const getCurrentTime = useCallback(() => player && player.getPlayerState() === 1 ? player.getCurrentTime() : null, [player])

  useEffect(() => {
    /* TODO: this is only for isLive = false */
    if (player && !playerDefaultsSet) {
      player.setVolume(0);
      setPlayerDefaultsSet(true);
    }
  }, [player, playerDefaultsSet]);

  useEffect(() => {
    if (trackManager) {
      return () => trackManager.dispose();
    }
  }, [trackManager]);

  useEffect(() => {
    if (!videoSender) {
      return;
    }
    return () => videoSender.stop();
  }, [videoSender]);

  // no useCallback because this cb requires the universe anyway
  const startRecording = async () => {
    setStarted(true);
    const tm = new TrackIdManager(sessionId, webSocket, logger, metadata, onFinish);
    const trackId = await tm.getTrackId(draftMode)
    if (videoMode !== 0) { 
      const videoSender = makeVideoSender(webSocket);
      const timeCorrectedGetCurrentTime = () => {
        const raw = getCurrentTime();
        return raw && raw-micOffset >= 0 ? raw-micOffset : null
      }
      await videoSender.start(trackId, mediaStream, loopBackVideoRef.current, timeCorrectedGetCurrentTime);
      setVideoSender(videoSender)
    }
    const audioSender = new RecordingSender(tm, sessionId, webSocket, player, mediaStream, micOffset, logger);
    setStarted(audioSender.start(0, trackId, !!draftMode));
    setAudioSender(audioSender);
    setTrackManager(tm);    
  }
  
  const stopRecording = useCallback(() => {
    if (trackManager) {
      trackManager.cancel();
    }
    if (audioSender) {
      audioSender.stop();
      setAudioSender(null);
    }
    if (videoSender) {
      videoSender.stop()
      setVideoSender(null);
    }
    setStarted(false);
    
    if (cancelCount === 2) {
      showNotification(
        "Trying to get that perfect take?",
        <>
          <p>
            Your recording is more than just a contribution to the final mix.
            It also adds to a <b>backing track</b> that makes it easier for
            other participants to record their part.
          </p> 
          <p>
            Don&apos;t strive for perfection! Even if you make mistakes or don&apos;t
            get that perfect sound right now, your contribution can help other 
            participants. <b>Think of it as a rehearsal for now</b>, and not a studio recording.
          </p>
          <p>
            The <b>secret to a really good take</b> is to add an imperfect track now, and 
            come back a bit later when there are more people in the mix. You can then replace
            your old take with a better one that is supported by all other 
            participants. That&apos;s the easiest, and most fun, way to
            make music with Duruflé.
          </p>
        </>
      );
    }
    setCancelCount(c => c+1);
  }, [trackManager, audioSender, videoSender, cancelCount, showNotification])

  if (!webSocket) {
    return <Loading big>Loading...</Loading>
  }

  return (
    <div className="recorder">
      <VideoFrame>
        <div className="recorder__videoOverlay">
          {videoMode !== 0 && <SynchronisedVideoSender ref={loopBackVideoRef} />}
          {!playing && <button className="recorder__start" disabled={!webSocket || !player || started || playing} onClick={startRecording}>Start session...</button>}
          {playing && player && started && <button className="recorder__cancel" onClick={stopRecording}>Cancel session!</button>}
        </div>
        </VideoFrame>
      <VideoProgress ref={playerRef} getCurrentTimestamp={getCurrentTime} duration={player ? player.getDuration() : 0} />
      <h3>
        Volume control
      </h3>
      <SynchronisedPlayback getCurrentTimestamp={getCurrentTime} offset={speakerOffset}>
        {/* TODO: this is only for isLive = false */}
        <SynchronisedPlayback.Audio src={apiRoutes.backingTrackStream(sessionId)} label="Backing track" controls />
        {canHearParticipants &&
          <SynchronisedPlayback.Audio src={apiRoutes.stream(sessionId)} initialVolume={hearParticipants ? 1 : 0} label="Other participants" controls />
        }
      </SynchronisedPlayback>
      {notificationElement}
    </div>
  );
}

export default Recorder;