import React, { useState, useMemo, useEffect } from 'react';
import VideoTest from '../VideoTest';
import ScannableWidth from '../ScanableWidth';

const useCameraTestPage = ({mediaStream, videoData}) => {
  const videoMode = videoData && videoData.videoMode;
  
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    if (videoMode === 0) {
      setComplete(true);
    }
  }, [videoMode]);
  
  const element = useMemo(() => {
    if (!complete) {
      return (
        <ScannableWidth>
          {videoMode !== 0 && <>
            <h3>1. Set up your video</h3>
            <VideoTest mediaStream={mediaStream} />
          </>}
          <p>
            When you&apos;re finished setting up your camera, we will configure your microphone. 
          </p>
          <button onClick={() => setComplete(true)}>I&apos;m in position &ndash; continue</button>
        </ScannableWidth>
      );
    }

    return null;
  }, [mediaStream, videoMode, complete]);

  return [element];
}

export default useCameraTestPage;