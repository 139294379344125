const pluralUnit = (amount, factor, unit) => {
  const a = Math.floor(amount / factor);
  return a === 0 ? null : `${a} ${unit}${a===1 ? "" : "s"}`;
}

export const toAge = (ticks) => {
  const d = new Date().valueOf() - ticks;

  return pluralUnit(d, 365.25 * 24 * 60 * 60000, "year") 
      || pluralUnit(d, 30 * 24 * 60 * 60000, "month")
      || pluralUnit(d, 24 * 60 * 60000, "day")
      || pluralUnit(d, 60 * 60000, "hour")
      || pluralUnit(d, 60000, "minute")
      || "moments";
}

const pad = (number, length) => (new Array(length).fill("0").join("") + number).slice(-length)

export const toTimestamp = (ticks) => {
  const t = new Date(ticks);
  return `${t.toLocaleDateString()} ${pad(t.getHours(), 2)}:${pad(t.getMinutes(), 2)}`;
}
