import React from 'react';
import Layout from './Layout';
import Sessions from './Sessions';

import {
  BrowserRouter,
  Switch,
  Route
} from 'react-router-dom'
import Helmet from 'react-helmet';
import AddNewSession from './AddNewSession';
import RecordingWorkflow from './RecordingWorkflow/RecordingWorkflow';
import DownloadSession from './DownloadSession';
import Home from './Home';
import useChat from './useChat';
import StreamSession from './StreamSession';
import Page from './Page';
import PublicSessions from './PublicSessions';
import UndoTrack from './UndoTrack';
import {AuthenticationProvider, AuthenticationForm} from './Authentication';
import Error from './Error';
import UserProfile from './UserProfile';
import SessionStatus from './SessionStatus';
import InviteParticipants from './InviteParticipants';
import { UploadContributionProvider } from './UploadContribution';
import AddNote from './AddNote';

function App() {
  useChat();

  return (
    <AuthenticationProvider signInPath={"/signin"}>
      <UploadContributionProvider>
        <BrowserRouter>
          <Helmet>
            <title>Duruflé - Make music anywhere</title>
          </Helmet>
          <Layout>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/profile">
                <Page>
                  <UserProfile />
                </Page>
              </Route>
              <Route exact path="/signin">
                  {({location}) => (
                    <Page>
                      <AuthenticationForm 
                        to={new URLSearchParams(location.search).get("to")} 
                        as={new URLSearchParams(location.search).get("as")} 
                        anonymous={new URLSearchParams(location.search).get("anonymous") === "1"} 
                      />
                    </Page>
                  )}
                </Route>
              <Route exact path="/sessions">
                <Page>
                  <PublicSessions />  
                </Page>
              </Route>
              <Route exact path="/admin">
                <Page>
                  <Sessions />
                </Page>
              </Route>
              <Route exact path="/admin/session/:sessionId">
                {({match}) => (
                  <Page>
                    <SessionStatus sessionId={match.params.sessionId} />
                  </Page>
                )}
              </Route>
              <Route exact path="/admin/session/:sessionId/invite">
                {({match}) => (
                  <Page>
                    <InviteParticipants sessionId={match.params.sessionId} />
                  </Page>
                )}
              </Route>
              <Route exact path="/admin/session/:sessionId/addNote">
                {({match}) => (
                  <Page>
                    <AddNote sessionId={match.params.sessionId} />
                  </Page>
                )}
              </Route>
              <Route exact path="/admin/addsession">
                <Page>
                  <AddNewSession onClose={() => window.location = "/admin"} />
                </Page>
              </Route>
              <Route exact path="/sessions/:sessionId/download">
                {({match}) => (
                  <Page>
                    <DownloadSession sessionId={match.params.sessionId} />
                  </Page>
                )}  
              </Route>
              <Route exact path="/sessions/:sessionId/stream">
                {({match, location}) => (
                  <Page>
                    <StreamSession sessionId={match.params.sessionId} rtr={new URLSearchParams(location.search).get("rtr")} share={new URLSearchParams(location.search).get("share")} />
                  </Page>
                )}  
              </Route>
              <Route exact path="/sessions/:sessionId/track/:trackId/undo">
                {({match}) => (
                  <Page>
                    <UndoTrack sessionId={match.params.sessionId} trackId={match.params.trackId} />
                  </Page>
                )}
              </Route>
              <Route exact path="/record/:sessionId">
                {({match}) => (
                  <Page>
                    <RecordingWorkflow sessionId={match.params.sessionId} />
                  </Page>
                )}
              </Route>

              <Route>
                <Page>
                  <Error>
                    <Error.Title>
                      I don&apos;t know that page
                    </Error.Title>
                    <Error.Message>
                      This page doesn&apos;t exist. Maybe you used an outdated link?
                    </Error.Message>
                  </Error>
                </Page>
              </Route>
            </Switch>
          </Layout>
        </BrowserRouter>
      </UploadContributionProvider>
    </AuthenticationProvider>
  );
}

export default App;
