import React, { useContext, useEffect, useState } from "react"

import useLogger from "./useLogger";
import useApiFetch from "./useApiFetch";

const HOST = process.env.REACT_APP_HOST;

const UploadContribution = React.createContext();

export const UploadContributionProvider = ({children}) => {
  const fetch = useApiFetch();
  const logger = useLogger();

  const [uploader, setUploader] = useState();

  useEffect(() => {
    let queue = [];
    let corks = [];
    let mutex = null;

    async function doFetch(type, data, binary) {
      const formData = new FormData();
      formData.append('metadata', JSON.stringify(data));
      if (binary) {
        formData.append('binary', binary, 'file');
      }

      return await fetch(`${HOST}/contribute/${type}`, {
        method: "POST",
        body: formData,
      })
      .then(x => x.text())
      .catch(e => logger.error(`Failed contribution type ${type}, reason: ${e.message}`));
    }

    async function maybeProcessQueue() {
      if (!!mutex) {
      // console.log('dont process: mutex in flight', mutex)
        await mutex;
        return;
      }
      mutex = new Promise(async ok => {
        while (!corks.length && queue.length) {
          const [type, data, binary] = queue.shift();
          // console.log('process queued', type, queue.length)
          await doFetch(type, data, binary);
        }
        setTimeout(() => {
          mutex = null;
          ok()
        });
      });
      await mutex;
      return;
    }

    setUploader({
      emitNow: async(type, data, binary) => {
        // console.log('do immediately', type)
        return await doFetch(type, data, binary);
      },
      emit: async (type, data, binary) => {
        // console.log('queue up', type)
        queue.push([type, data, binary]);
        await maybeProcessQueue();
      },
      cork: () => {
        // console.log('corking!', corks.length + 1)
        const theCork = Symbol();
        corks.push(theCork);
        return async () => {
          // console.log('uncorking', corks.length - 1);
          corks = corks.filter(x => x !== theCork);
          await maybeProcessQueue();
        }
      },
      uncork: async () => {
        // console.log('uncorking all');
        corks = [];
        await maybeProcessQueue();
      },
      reset: () => {
        queue = [];
      },
      getQueueLength: () => queue.length
    });
  }, [fetch, logger]);

  return (
    <UploadContribution.Provider value={uploader}>
      {children}
    </UploadContribution.Provider>
  )
}

export const useUploadContribution = () => {
  return useContext(UploadContribution)
}