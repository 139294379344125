const AudioContext = window.AudioContext || window.webkitAudioContext;

const SAMPLE_RATE = 48000;
const SAMPLE_TOLERANCE = 1000;
const MAXIMUM_OUT_OF_BOUNDS_COUNT = 10;

export default class RecordingSender {
  constructor(trackManager, sessionId, socket, player, stream, micOffset, logger) {
    this.stream = stream;
    this.player = player;
    this.sessionId = sessionId;
    this.micOffset = micOffset;
    this.logger = logger
    this.socket = socket;
    this.trackManager = trackManager;
  }

  start(startSecs, trackId, isDraft) {

    if (!this.player) {
      return false;
    }

    if (startSecs !== undefined) {
      this.player.seekTo(startSecs);
    }
    this.player.playVideo();
    let currentTime = 0;

    this.ctx = new AudioContext();

    if (this.ctx.sampleRate !== SAMPLE_RATE) {
      this.logger.warn(`recording in non-standard sample rate: ${trackId} at ${this.ctx.sampleRate}, vs the default ${SAMPLE_RATE}`);
    }

    const source = this.ctx.createMediaStreamSource(this.stream);
    const scriptProcessor = this.ctx.createScriptProcessor(16384, 1, 1);
    
    if (this.stream.getAudioTracks().length === 1) {
      source.connect(scriptProcessor);
    } else {
      // enforce mono
      const merger = this.ctx.createChannelMerger(this.stream.getAudioTracks().length);
      source.connect(merger);
      merger.connect(scriptProcessor);
    }

    // bugfix - chrome (and edge) require a destination for the scriptProcessor events to fire.
    scriptProcessor.connect(this.ctx.destination);

    // the number of samples by which the video is ahead of the recording
    let sumOffset = 0;

    // whether the timestamps were ever synchronised
    let lockedIn = false;
    let outOfBoundsCount = 0;

    scriptProcessor.onaudioprocess = (event) => {
      if (this.player && this.player.hasFinished()) {
        this.trackManager.finish();
        this.stop(true);
      }

      const endTime = this.player && this.player.getCurrentTime() - this.micOffset;

      if (!this.player) {
        this.logger.info('discard chunk: no player', trackId);
        lockedIn = false;
      } else if (!(endTime > 0)) {
        // playback hasn't started yet
        lockedIn = false;
      } else if (this.player.getPlaybackRate() !== 1 || this.player.getPlayerState() !== 1) {
        // player isn't playing
        lockedIn = false;
      } else if (trackId === undefined) {
        this.logger.info('discard chunk: no trackId', trackId);
        lockedIn = false;
      }
      else {
        const startTime = currentTime;
        currentTime = endTime;
        const data = event.inputBuffer.getChannelData(0);
        
        const d = this.player.getDuration();
        const finished = this.player.hasFinished();
        const finishing = finished || (d > 0 && endTime/d > 0.7);
        
        const targetSamples = Math.round(this.ctx.sampleRate * (endTime - startTime));
        sumOffset += (targetSamples - data.length);

        let processedData;
        let logMessage = null;
        if (sumOffset > 0) {
          // gap in the recording - but it may average out. are we within tolerance?
          if (sumOffset < SAMPLE_TOLERANCE) {
            // yes! add sample without gap and add to sumOffset
            processedData = data;
            lockedIn = true;
            outOfBoundsCount = 0;
          } else if (lockedIn && ++outOfBoundsCount <= MAXIMUM_OUT_OF_BOUNDS_COUNT) {
            // no, but still in time-tolerance...
            processedData = data;
          } else {
            // no! add a gap sufficient to reset the offset
            this.logger.info("event: gap!", trackId)
            logMessage = "event: gap!";
            processedData = new Float32Array(data.length + sumOffset);
            processedData.fill(0, 0, sumOffset);
            processedData.set(data, sumOffset);
            sumOffset = 0;
            lockedIn = true;
            outOfBoundsCount = 0;
          }
        } else if (sumOffset < 0) {
          // overlap in the recording - but it may average out. are we within tolerance?
          if (sumOffset > -SAMPLE_TOLERANCE) {
            // yes! add sample without gap and add to sumOffset
            processedData = data;
            lockedIn = true;
            outOfBoundsCount = 0;
          } else if (lockedIn && ++outOfBoundsCount <= MAXIMUM_OUT_OF_BOUNDS_COUNT) {
            // no, but still in time-tolerance...
            processedData = data;
          } else {
            // no! truncate data to reset sumOffset as much as possible
            if (data.length > -sumOffset) {
              // only part of the data needs to be truncated
              this.logger.info("event: partial truncation!", trackId)
              logMessage = "event: partial truncation!";
              processedData = data.slice(-sumOffset);
              sumOffset = 0;
              outOfBoundsCount = 0;
              lockedIn = true;
            } else {
              // all data should be truncated
              this.logger.info("event: full truncation!",)
              logMessage = "event: full truncation!";
              sumOffset += data.length;
              processedData = new Float32Array(0); 
              lockedIn = false; // NB!!!
            }
          }
        } else {
          // edge case - the sample counts match exactly! 
          processedData = data;
          lockedIn = true;
        }

        const payload = {
          ...this.metadata,
          isDraft,
          start: startTime,
          end: endTime,
          sessionId: this.sessionId,
          trackId: trackId,
          finished: finishing,
          sampleRate: this.ctx.sampleRate,
          channels: 1,
          logMessage,
          duration: d === 0 ? undefined : d
        };

        this.socket.emit('data', payload, new Blob([processedData], {type: "audio/wav"}));

      }
    };

    return true;
  }

  stop () {
    if (this.player) {
      this.player.stopVideo();
    }
    if (this.ctx) {
      this.ctx.close();
      this.ctx = null;
    }
  }
}