import React, { useEffect, useRef, useState, useContext, useCallback } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import './Authentication.css';

import Loading from './Loading';
import apiRoutes from './apiRoutes';

export const AuthContext = React.createContext();

export const useAuthentication = (opts) => {
  const history = useHistory();
  const location = useLocation();
  const { init, user, signInPath, setUser } = useContext(AuthContext);
  const [error, setError] = useState();

  if (typeof opts === "string") {
    opts = {privilege: opts};
  }

  if (error) {
    throw error;
  }
 
  if (!init) {
    return {};
  }

  if (!user && opts.allowAnonymous) {
    firebase.auth().signInAnonymously()
      .then(user => setUser(user.user))
      .catch(e => setError(e))

    return {};
  }

  const isAnonymous = user && !!user.claims.provider_id;

  if (!user || (opts.privilege && user.claims[opts.privilege] !== true) || (!opts.allowAnonymous && isAnonymous)) {
    history.push(`${signInPath}?to=${encodeURIComponent(location.pathname + location.search)}`);
    return {};
  }

  return user;
}

export const useAuthenticationState = () => {
  const { init, user } = useContext(AuthContext);

  const userState = !init ? useAuthenticationState.INITIALISING
    : !user ? useAuthenticationState.SIGNED_OUT
    : !!user.claims.provider_id ? useAuthenticationState.ANONYMOUS
    : useAuthenticationState.SIGNED_IN;

  return userState; 
}

useAuthenticationState.INITIALISING = "INITIALISING";
useAuthenticationState.SIGNED_OUT = "SIGNED_OUT";
useAuthenticationState.ANONYMOUS = "ANONYMOUS";
useAuthenticationState.SIGNED_IN = "SIGNED_IN";


export const AuthenticationForm = ({to, as}) => {
  const authRef = useRef();
  const userState = useAuthenticationState();
  const { authUi, user, setUser, signOut } = useContext(AuthContext);
  const [uiShown, setUiShown] = useState(false);

  const isUserMismatch = userState === useAuthenticationState.SIGNED_IN && !!as && user.claims.email !== as;

  useEffect(() => {
    if (isUserMismatch) {
      signOut();
    }
  }, [isUserMismatch, signOut]);

  useEffect(() => {
    if (uiShown && as) {
      authRef.current.querySelector('input[name="email"]').setAttribute("disabled", "disabled");
      authRef.current.querySelector('input[name="email"]').setAttribute("value", as);
      authRef.current.querySelector('.firebaseui-id-submit').click();
      const interval = setInterval(() => {
        const found = authRef.current.querySelector('input[name="email"]')
        if (!found.disabled) {
          found.setAttribute("disabled", "disabled");
          clearInterval(interval)
        }
      }, 10);
    }
  }, [authRef, uiShown, as])

  // console.log('authstate', userState, isUserMismatch, as, user);

  useEffect(() => {
    if (!!authUi && userState !== useAuthenticationState.SIGNED_IN) {
      const anonymousUser = user;
      const CONFIG = {
        credentialHelper: firebaseui.auth.CredentialHelper.NONE,
        autoUpgradeAnonymousUsers: true,
        signInOptions: [
          firebase.auth.EmailAuthProvider.PROVIDER_ID
        ],
        tosUrl: "https://www.durufle.app",
        privacyPolicyUrl: "https://www.durufle.app",
        // signInSuccessUrl: to,
        callbacks: {
          uiShown: () => setUiShown(true),
          signInSuccessWithAuthResult: function (authResult) {
            setUser(authResult.user);
            return false;
          },
          signInFailure: function (error) {
            if (error.code !== "firebaseui/anonymous-upgrade-merge-conflict") {
              return Promise.resolve()
            }

            return firebase.auth().signInWithCredential(error.credential)
              .then(user => {
                return user.user.getIdToken()
              })
              .then(toToken => fetch(apiRoutes.mergeUsers, {
                headers: {
                  "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify({
                  fromToken: anonymousUser.token,
                  toToken: toToken
                })
              }))
              .then(() => anonymousUser.delete())
          }
        },
      }
      authUi.start(authRef.current, CONFIG);
    }
  // eslint-disable-next-line
  }, [authRef, !!authUi, userState])

  if (userState === useAuthenticationState.INITIALISING || !authUi) {
    return (
      <div className="authentication">
        {!uiShown && <Loading>Loading...</Loading>}
      </div> 
    )
  } else if (userState !== useAuthenticationState.SIGNED_IN || isUserMismatch) {
    return (
      <div className={`authentication ${!!as ? "authentication--fixedid" : ""}`}>
        <div ref={authRef}></div>
      </div>
    );
  } else {
    return <Redirect push to={to} />;
  }
}

export const AuthenticationProvider = ({children, signInPath}) => {
  const [user, setUser] = useState();
  const [tokenUser, setTokenUser] = useState();
  const [authUi, setAuthUi] = useState();
  const [init, setInit] = useState();

  const setUserAndToken = useCallback((user) => {
    if (!user) {
      setUser(null);
      setInit(true);
    } else {
      user.getIdTokenResult()
        .then((res) => {
          setUser(res);
          setTokenUser(user);
          setInit(true);
        });
    }
  }, []);

  useEffect(() => {
    const FIREBASE_CONFIG = {
      apiKey: "AIzaSyCn4y3Sj5u42pwNRS3qQ5yv0Wc4E_5l6x4",
      authDomain: "durufle-ddf89.firebaseapp.com",
      databaseURL: "https://durufle-ddf89.firebaseio.com",
      projectId: "durufle-ddf89",
      storageBucket: "durufle-ddf89.appspot.com",
      messagingSenderId: "544058430160",
      appId: "1:544058430160:web:9d16b2034f51d6dcf744d5"
    };
    firebase.initializeApp(FIREBASE_CONFIG);
    const auth = firebase.auth();
    auth.onAuthStateChanged(user => {
      setUserAndToken(user);
    });
    setAuthUi(new firebaseui.auth.AuthUI(auth));
  }, [setUserAndToken])

  const signOut = useCallback(() => {
    if (init) {
      firebase.auth().signOut()
    }
  }, [init]); 

  const value = {init, user, tokenUser, setUser: setUserAndToken, signInPath, authUi, signOut};

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );      
}
