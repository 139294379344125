import React, { useState, useEffect, useMemo } from 'react';
import Recorder from '../Recorder';
import ScanableWidth from '../ScanableWidth';

const useRecorderPage = ({sessionId, mediaStream, videoData = {}, audioOffset, visualOffset, canHearParticipants, hearParticipants, tokenUser, name, part, shouldOverride}) => {
  const [finishedTrack, setFinishedTrack] = useState();
  
  useEffect(() => {
    if (mediaStream && finishedTrack) {
      mediaStream.getTracks().forEach(t => t.stop());
    }
  }, [mediaStream, finishedTrack])

  const element = useMemo(() => {
    if (finishedTrack) {
      return null;
    }

    const speakerOffset = audioOffset !== null && visualOffset !== null ? (audioOffset - visualOffset) / 1000 : null;
    const micOffset = visualOffset !== null ? visualOffset / 1000 : null;

    return (
      <>
        <Recorder 
          canHearParticipants={canHearParticipants} 
          hearParticipants={hearParticipants} 
          tokenUser={tokenUser} 
          videoMode={videoData.videoMode} 
          sessionId={sessionId} 
          mediaStream={mediaStream} 
          metadata={{name, part}} 
          onFinish={(trackId) => setFinishedTrack(trackId)}
          speakerOffset={speakerOffset}
          micOffset={micOffset}
          draftMode={shouldOverride} /* overrides should only become active on finish */
        />
        <ScanableWidth>
          <h3>Tips</h3>
          <ul>
            <li>Begin by clicking on the "Start session" button.</li>
            <li>Please <span role="img" aria-label="">&#x1F3A7;</span> <b>wear head phones</b> while recording</li>
            <li>Got interrupted? No worries! You can just click "Cancel session", and start over when you're ready</li>
            <li>Let the video run to its end to finish your recording session</li>
          </ul>
        </ScanableWidth>
      </>
    )
  }, [sessionId, mediaStream, audioOffset, visualOffset, videoData, canHearParticipants, hearParticipants, tokenUser, name, part, finishedTrack, shouldOverride]);

  return [element, {finishedTrack}];
}

export default useRecorderPage;