import React, { useMemo } from 'react';
import { detect as detectBrowser } from 'detect-browser';

import Error from '../Error';

function isBrowserSupported() {
  const browser = detectBrowser();
  return ["chrome", "firefox", "chromium-webview", "crios", "fxios", "ios-webview" /*, "safari"*/].indexOf(browser && browser.name) > -1;
}

const useBrowserSupportError = () => {
  const element = useMemo(() => {
    if (!isBrowserSupported()) {
      return (
        <Error>
          <Error.Title>Sorry, you&apos;ll need a different browser.</Error.Title>
          <Error.Message>
            Unfortunately, your browser doesn&apos;t support recording in Duruflé. Please use{" "}
            <a href="https://www.mozilla.org/firefox/new/" target="_blank" rel="noopener noreferrer">Firefox</a>{" "}
            or{" "}
            <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">Chrome</a>{" "}
            instead.
          </Error.Message> 
        </Error>
      )
    } else {
      return null;
    }
  }, []);

  return [element]
}

export default useBrowserSupportError;
