import React, { useEffect, useRef, useState } from 'react';
import './VideoTest.css';
import Silhouette from './Silhouette';

const determineNote = () => window.innerWidth < window.innerHeight
  ? "Tip: Video works best if you turn your device to landscape"
  : "You look great!"

const VideoTest = ({mediaStream}) => {
  const ref = useRef();
  const [note, setNote] = useState(determineNote());
  
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if ('srcObject' in ref.current) {
      ref.current.srcObject = mediaStream;
    } else {
      // Avoid using this in new browsers, as it is going away.
      ref.current.src = URL.createObjectURL(mediaStream);
    }
  }, [ref, mediaStream])

  useEffect(() => {
    const onResize =  () => {
      setNote(n => determineNote());
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <>
      <p>Make sure you are well-positioned in the video frame. Try to <b>match the silhouette</b>: be centered, not too close, not too far.</p>
      <div className="videoTest__wrapper">
        <video autoPlay muted className="videoTest__video" ref={ref} />
        <Silhouette />
      </div>
      <div className="videoTest__compliment">{note}</div>
    </>
  )
}

export default VideoTest;