import React from 'react';

function launchChat(e) {
  e.preventDefault();
  const d = window.drift;
  if (!d) {
    return;
  }
  d.on('ready', api => {
    api.sidebar.open();
  })
}

const ChatLink = ({children}) => 
    <button className="button--link" onClick={launchChat}>{children}</button>;

export default ChatLink;