import { useContext, useCallback } from "react"
import { AuthContext } from "./Authentication"

const decorateWithToken = (init, token) =>  {
  return {
    ...(init || {}),
    headers: {
      ...((init && init.headers) || {}),
      Authorization: `Basic ${token}`
    }
  }
}

export default function useApiFetch() {
  const { tokenUser } = useContext(AuthContext);
  return useCallback(async (request, init) => {
    const token = tokenUser && await tokenUser.getIdToken();
    if (!token) {
      return fetch(request, init);
    }
  
    return await fetch(request, decorateWithToken(init, token));
  }, [tokenUser]);
}