import React, { useState, useEffect, useMemo } from 'react';
import Cookies from 'js-cookie';
import ScannableWidth from '../ScanableWidth';

const getCalibrationFromCookie = mediaStream => {
  const track = mediaStream && mediaStream.getAudioTracks()[0];
  const device = track && track.label;
  const calibration = Cookies.getJSON('calibration');
  const isValid = calibration && calibration.device === device && calibration.audioOffset !== undefined && calibration.visualOffset !== undefined;
  
  return isValid ? {
    isValid,
    audioOffset: calibration.audioOffset,
    visualOffset: calibration.visualOffset
  } : {isValid};
}

const useReuseCalibrationPage = ({mediaStream}) => {
  const [shouldShow, setShouldShow] = useState(false);
  const [audioOffset, setAudioOffset] = useState(null);
  const [visualOffset, setVisualOffset] = useState(null);

  useEffect(() => {
    const calibration = getCalibrationFromCookie(mediaStream);

    if (calibration.isValid) {
      if (audioOffset !== calibration.audioOffset) {
        setAudioOffset(calibration.audioOffset)
      }
      if (visualOffset !== calibration.visualOffset) {      
        setVisualOffset(calibration.visualOffset);
      }
      setShouldShow(true);
    }
  // eslint-disable-next-line
  }, [mediaStream]);

  useEffect(() => {
    if (mediaStream && audioOffset !== null && visualOffset !== null) {
      Cookies.set('calibration', {
        device: mediaStream.getAudioTracks()[0].label,
        audioOffset,
        visualOffset
      }, {
        expires: 99999
      });
    }
  }, [mediaStream, audioOffset, visualOffset]);

  const element = useMemo(() => {
    if (shouldShow) {
      return ( 
        <ScannableWidth>
          <h3>Reuse previous calibration?</h3>
          <p>You have previously set up your microphone and speakers for use with Duruflé. Do you want to use your existing calibration?</p>
          <p>If you have changed your microphone or speakers since last time, it&apos;s best to recalibrate.</p>
          <button onClick={() => setShouldShow(false)}>Yes &ndash; use my existing calibration</button>
          <p>
            <button className="button--link" onClick={() => {setAudioOffset(null); setVisualOffset(null); setShouldShow(false)}}>No &ndash; let me recalibrate</button>
          </p>
        </ScannableWidth>
      );
    }
    return null;
  }, [shouldShow]);

  return [element, {audioOffset, setAudioOffset, visualOffset, setVisualOffset}];  
}

export default useReuseCalibrationPage;