import React, { useState, useCallback } from 'react';
import extractData from './util/extractData';
import routes from './apiRoutes';
import Form from './Form';
import useApiFetch from './useApiFetch';
import useNotification from './useNotification';
import { Redirect } from 'react-router-dom';

const AddNote = ({sessionId}) => {
  const [done, setDone] = useState(false);
  const [submitting, setSubmitting] =  useState(false);
  const fetch = useApiFetch();
  const [show, notificationElement] = useNotification();

  const handleData = useCallback((data) => {
    if (!data.body) {
      show("Some data is missing", <p>Please enter the note text</p>);
      return;
    }

    fetch(routes.sessionNote(sessionId), {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        body: data.body,
        sendNotification: data.notify && data.notify[0] ? "1" : "0"
      })   
    }).then(() => {
        setSubmitting(false);
        setDone(true);
      })
      .catch(e => {
        show("Error", <>{e.message}</>);
        setSubmitting(false);
      });
  }, [fetch, show, sessionId])

  const cancel = useCallback(e => {
    e.preventDefault();
    setDone(true);
  }, []);

  if (done) {
    return <Redirect push to={`/admin/session/${sessionId}`} />
  }

  return (
    <>
      <h2>Add a performance note</h2>
      {notificationElement}
      <Form onSubmit={extractData(handleData)}>
        <label htmlFor="body">
          Enter your note
        </label>
        <textarea name="body" id="body" rows={4} />
        <fieldset>
          <legend>
            Notify participants about this note?
            <span className="form__hint">Session participants will receive an email inviting them to redo their recording based on your note.</span>
          </legend>  
          <label>
            <input type="checkbox" name="notify" value="1"/>
            Yes, notify participants
          </label>
        </fieldset>

        <button disabled={submitting}>Create performance note</button>
        <button className="button--link" onClick={cancel}>Cancel</button>
      </Form>
    </>
  )
}

export default AddNote;