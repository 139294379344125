import React, { useState, useEffect } from "react";
import apiRoutes from "./apiRoutes";
import ChatLink from "./ChatLink";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import './PublicSessions.css';
import useApiFetch from "./useApiFetch";

const SessionList = () => {
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState(true);
  const [error, setError] = useState();
  const fetch = useApiFetch();

  useEffect(() => {
    fetch(apiRoutes.session)
      .then(r => r.json())
      .then(s => {
        setSessions(s);
        setLoading(false);
      })
      .catch(() => setError(true));
  }, [fetch]);

  if (error) {
    return <p><em>Could not load sessions. Please try again later</em></p>
  }
  if (loading) {
    return <Loading big>Loading sessions</Loading>
  }
  if (!sessions || !sessions.length) {
    return <p><em>There are no sessions available at the moment. Please try again later</em></p>
  }

  // TODO: when access-right work is done, this filter goes server-side!!
  return (
    <ul className="publicSessions__list">
      {sessions.filter(x => x.isPublic).map(x => (
        <li key={x.sessionId}>
          <h3 className="publicSessions__header">
            {x.title}
            {x.isLive && <span className="publicSessions__live">live</span>}
          </h3>
          <p className="publicSessions__host">
            Hosted by {x.host}
          </p>
          {x.description ? x.description.split("\n").filter(x => !!x).map(x => (
            <p key={x}>{x}</p>
          )) : <p>&nbsp;</p>}
          {x.existingPart &&
            <p className="publicSessions__contribution">
              <span role="img" aria-label="">&#10004;</span> You have contributed {x.existingPart.match(/^[aeio]/i) ? "an" : "a"} {x.existingPart} part 
            </p>
          }
          <Link className="publicSessions__cta" to={`/record/${x.sessionId}`}>View details</Link>
          <ul className="publicSessions__features">
            <li>{x.completeCount} musicians participated</li>
            {x.sheetUrl && <li><span role="img" aria-label="">&#10004;</span> Sheet music <a href={x.sheetUrl} target="_blank" rel="noopener noreferrer">available</a></li>}
            {x.videoMode !== 0 && <li><span role="img" aria-label="">&#127909;</span> this session has video recording enabled</li>}
          </ul>
        </li>
      ))}
    </ul>
  )
}

const PublicSessions = () => (
  <>
    <h2>Public sessions</h2>
    <p>
      The sessions below are open for anyone to participate - pick one and start making music! If you want to host your own session, <ChatLink>get in touch</ChatLink>.
    </p>
    <SessionList />
  </>
)

export default PublicSessions;