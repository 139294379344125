import React, { useState, useEffect, useMemo } from 'react';
import Error from '../Error';

const useAcquireMediaStreamPage = ({videoData}, isNextUp) => {
  const [mediaStream, setMediaStream] = useState();

  const enableCamera = videoData && videoData.videoMode !== 0;
  
  useEffect(() => {
    if (!isNextUp) {
      // only request mediastream once past the checkpoints
      // for authenticated users
      return;
    }
    if (mediaStream) {
      // close down the page when navigating away
      return () => mediaStream.getTracks().forEach(t => t.stop());
    }
    window.navigator.mediaDevices.getUserMedia({
      audio: {
        channelCount: { exact: 1 },
        autoGainControl: false,
      },
      video: enableCamera
    })
      .then(setMediaStream)
      .catch(() => setMediaStream(false));
  }, [mediaStream, enableCamera, isNextUp]);

  const element = useMemo(() => {
    if (mediaStream === undefined) {
      return (
        <div>
          Please allow this page access to your
          {enableCamera ? " camera and " : " "}
          microphone...
        </div>
      );
    }

    if (mediaStream === false) {
      const devices = enableCamera ? "camera or microphone" : "microphone"
      return (
        <Error>
          <Error.Title>There&apos;s an issue with your {devices}.</Error.Title>
          <Error.Message>
            We could not get permission to record you, or another app is currently using your {devices}. Reload to try again.
          </Error.Message> 
        </Error>
      );
    }
    return null;    
  }, [mediaStream, enableCamera]);

  return [element, {mediaStream}];
}

export default useAcquireMediaStreamPage;