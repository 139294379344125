import React, { useState, useEffect, useMemo } from 'react';
import apiRoutes from '../apiRoutes';
import Error from '../Error';
import { useAuthentication } from '../Authentication';
import Loading from '../Loading';
import useApiFetch from '../useApiFetch';

const useLoadSessionDataPage = ({sessionId}) => {
  const [loading, setLoading] = useState(true);
  const [videoData, setVideoData] = useState();
  const [breakdownData, setBreakdownData] = useState();

  // this is necessary to load the
  // session data
  useAuthentication({
    allowAnonymous: true
  })

  const fetch = useApiFetch();

  useEffect(() => {
    Promise.all([
      fetch(apiRoutes.sessionDetails(sessionId))
        .then(res => res.json())
        .then(setVideoData),
      fetch(apiRoutes.trackBreakdown(sessionId))
        .then(res => res.json())
        .then(setBreakdownData)
    ])
      .then(() => setLoading(false))
      .catch(() => setTimeout(() => setLoading(false), 5000));
  }, [sessionId, fetch]);

  const element = useMemo(() => {
    if (loading) {
      return <Loading big>Loading...</Loading>
    }

    if (!videoData) {
      return (
        <Error>
          <Error.Title>Something went wrong</Error.Title>
          <Error.Message>
            We could not load the details of this session. Reload to try again.
          </Error.Message> 
        </Error>
      )
    }

    return null;
  }, [loading, videoData])

  return [element, {videoData, breakdownData}];
}

export default useLoadSessionDataPage;